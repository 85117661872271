import React, { Component } from "react";
import {
  // BrowserRouter as Router,
  Route,
  // Link,
  NavLink,
  Switch
  // Redirect
} from "react-router-dom";
import { connect } from "react-redux";
// import { whichPage } from '../redux/actions';

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
// import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from "@material-ui/core/Toolbar";
// import logoo from '../assests/LogoB.png';
import logoo from "../assests/rev-logo.png";
import logonewproject from "../assests/newproject.svg";
// import logosignout from '../assests/Usersignout.svg';
import logosignout from "../assests/logout.svg";
import {
  Image
  //  Modal
} from "semantic-ui-react";
import NewProject from "./newproject";
import ListProjects from "./listprojects";
import Uploadpage from "./uploadpage";
import apiRootUrl from "../api";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.handlelogout = this.handlelogout.bind(this);
  }
  handlelogout() {
    console.log("HANDILING LOGUT");
    let bodyy = {
      client_id: "QjbQ7PyHXMzUa7Ab2NsoSgn5uyM2vAJYEYnmbtnA",
      client_secret:
        "kjTk37Bhz8W1pQdANVgxSbu2Stzcvi77to3xbhvYwDTxyNGdr3sgMCxGj1CqTc4wZFRGfNBe89PjI8T2lgQpql4PJKVMOko3wn3JHXIYBhmftTJglKiMRc9wqBUkqEdj",
      token: this.props.backendtoken
    };
    fetch(`${apiRootUrl}/auth/revoke-token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bodyy)
    })
    .then(res=>{
      if(res.ok){
        localStorage.removeItem('accestoken');
        window.location.href =  'https://create.revlity.com/dashboard/';
      }
    });
  }
  render() {
    const pathname = document.location.pathname;
    return (
      <div>
        <div id="navbar-list">
          <AppBar
            position="static"
            style={{
              position: "fixed",
              backgroundColor: "#FFFFFF",
              boxShadow: "none"
            }}
          >
            <Toolbar style={{ paddingLeft: "3.4%", paddingRight: "3.4%" }}>
              {/* <Modal style={{width:"60%",height:'240px'}}
            trigger={
            <Button variant="contained" style={{ width: 105, height: 44, padding: 0, backgroundColor: '#FFFFFF', boxShadow: 'none' }}>
              <Image src={logoo} width='105px' height='44px' />
            </Button>
            }
            closeOnDimmerClick={false}
            >
            </Modal> */}
              <NavLink to="/dashboard">
                <Button
                  variant="contained"
                  style={{
                    width: 105,
                    height: 44,
                    padding: 0,
                    backgroundColor: "#FFFFFF",
                    boxShadow: "none"
                  }}
                >
                  <Image src={logoo} width="105px" height="44px" />
                </Button>
              </NavLink>
              <div style={{ flexGrow: 1 }}></div>
              <div style={{ marginRight: '3%' }}>
                {
                  ((pathname === "/dashboard/") || (pathname === "/dashboard")) ? (
                    <NavLink to='/dashboard/new'>
                      <Button variant="contained" style={{ width: '140px', height: '40px', padding: '0', boxShadow: 'none' }}>
                        <Image src={logonewproject} width='140px' height='40px' />
                      </Button>
                    </NavLink>
                  ) : null
                }
              </div>

              <Button
                variant="contained"
                style={{
                  width: 86,
                  height: 24,
                  padding: "0",
                  boxShadow: "none",
                  backgroundColor: "#FFFFFF"
                }}
                onClick={this.handlelogout}
              >
                <Image src={logosignout} width="86px" height="24px" />
              </Button>
            </Toolbar>
          </AppBar>
          <main
            style={{
              paddingTop: "92px",
              minHeight: "100vh",
              maxWidth: "100vw",
              backgroundColor: "#F3F3F3"
            }}
          >
            <Switch>
              <Route exact path="/dashboard/" component={ListProjects} />
              <Route path="/dashboard/new" component={NewProject} />
              <Route path="/dashboard/upload" component={Uploadpage} />
            </Switch>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    backendtoken: state.backendtokenReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBar);
