import * as THREE from 'three';
const OrbitControls = require('three-orbitcontrols')

class ThreeScene {
    constructor () {
      this.container1 = document.getElementById("container1");
      this._camera1 = new THREE.PerspectiveCamera(70, this.container1.offsetWidth/ this.container1.offsetHeight, 1, 1000)
      this._camera1.position.set(0, 0, .1);
      this._camera1.rotation.order = 'YXZ';

      this._scene1 = new THREE.Scene()

      this._renderer1 =  new THREE.WebGLRenderer({ antialias: true, stencil: false });
      this._renderer1.setSize(this.container1.offsetWidth, this.container1.offsetHeight);
      this.container1.appendChild(this._renderer1.domElement)
  
      this._controls1 = new OrbitControls(this._camera1, this._renderer1.domElement);
      this._controls1.maxPolarAngle = Math.PI / 2;
      this._controls1.minPolarAngle = Math.PI / 2;
      this._controls1.enablePan = false;

      window.addEventListener('resize', this.onWindowResize1.bind(this), false);


      this.container2 = document.getElementById("container2");
      this._camera2 = new THREE.PerspectiveCamera(70, this.container2.offsetWidth/ this.container2.offsetHeight, 1, 1000)
      this._camera2.position.set(0, 0, .1);
      this._camera2.rotation.order = 'YXZ';
      
      this._scene2 = new THREE.Scene()

      this._renderer2 =  new THREE.WebGLRenderer({ antialias: true, stencil: false });
      this._renderer2.setSize(this.container2.offsetWidth, this.container2.offsetHeight);
      this.container2.appendChild(this._renderer2.domElement)
  
      this._controls2 = new OrbitControls(this._camera2, this._renderer2.domElement);
      this._controls2.maxPolarAngle = Math.PI / 2;
      this._controls2.minPolarAngle = Math.PI / 2;
      this._controls2.enablePan = false;

      window.addEventListener('resize', this.onWindowResize2.bind(this), false);

    }

    get renderer1 () {
      return this._renderer1
    }

    get camera1 () {
      return this._camera1
    }

    get scene1 () {
      return this._scene1
    }


    get renderer2 () {
      return this._renderer2
    }

    get camera2 () {
      return this._camera2
    }

    get scene2 () {
      return this._scene2
    }

    onWindowResize1 () {
      this._camera1.aspect = this.container1.offsetWidth/ this.container1.offsetHeight
      this._camera1.updateProjectionMatrix()
      this._renderer1.setSize(this.container1.offsetWidth, this.container1.offsetHeight)
    }

    onWindowResize2 () {
      this._camera2.aspect = this.container2.offsetWidth/ this.container2.offsetHeight
      this._camera2.updateProjectionMatrix()
      this._renderer2.setSize(this.container2.offsetWidth, this.container2.offsetHeight)
    }


    animate (timestamp) {
      requestAnimationFrame(this.animate.bind(this))

      this._renderer1.render(this._scene1, this._camera1)
      this._controls1.update();

      this._renderer2.render(this._scene2, this._camera2)
      this._controls2.update();
    }

}
  
export default ThreeScene;