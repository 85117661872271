import React, { Component } from 'react'
import {
  NavLink,
} from "react-router-dom";
import { projectName, creatorName } from '../redux/actions';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import './styles/newproject.css';
import { Input } from 'semantic-ui-react';
import { Image } from 'semantic-ui-react';

import nexticon from '../assests/Next-icon.svg'


class NewProject extends Component {
  constructor(props){
    super(props);
    this.state={
      title:'Name of the Project',
      creatorname:"Creator Name",
      shownext:false
    }
    this.onChangehandle = this.onChangehandle.bind(this);
    this.onChangehandleCreator = this.onChangehandleCreator.bind(this);
  }


  onChangehandle(event){
    this.setState({title: event.target.value});
  }
  onChangehandleCreator(event){
    this.setState({creatorname: event.target.value});
  }

  clickonNext=()=>{
    this.props.titledispatch(this.state.title);
    this.props.creatordispatch(this.state.creatorname);
  }

  

  render() {
    return (
      <div style={{margin:'auto',width:'520px',height:'355px',paddingBottom:'100px'}}>
        <Card style={{width:'520px',height:'355px'}}>
            <CardHeader style={{height:'55px',backgroundColor:'#1E272E'}}
            action={((this.state.title!=='Name of the Project'))&&(this.state.creatorname!=='Creator Name')?
              <NavLink to='/dashboard/upload' onClick={this.clickonNext}>
              <CardHeader
              subheader='Next'
                action={
                  <IconButton style={{boxShadow:'none',marginTop:'-12px'}}>
                    <Image src={nexticon} width='18px' height='18px'/>
                  </IconButton>
                }
                classes={{subheader:'next-button-newproject'}}
              />
              </NavLink>:null
            }
            title='Create new project'
            classes={{title:'create-newproject-name'}}
            />
            <div style={{padding:50}}>
                <div>
                    <h4>Name of the project</h4>
                    <Input placeholder={this.state.title} style={{width:'100%'}} onChange={this.onChangehandle}/>
                </div>
                <div style={{paddingTop:40}}>
                    <h4>Creator name</h4>
                    <Input placeholder={this.state.creatorname} style={{width:'100%'}} onChange={this.onChangehandleCreator}/>
                </div>
            </div>
        </Card>
      </div>
    );
  };
};

const mapStateToProps = state => {
  return { 
      
  };
};

const mapDispatchToProps = dispatch => {
  return {
    titledispatch: title => dispatch(projectName(title)),
    creatordispatch: creator => dispatch(creatorName(creator))     
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(NewProject)