import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import cpoyimg from '../../assests/copy.svg';

export default class Publishsucess extends Component {
  state = {
    open:false
  }
  render() {
    return (
      <div>
        <Card style={{height:'511px'}} >
            <CardHeader style={{height:'55px',backgroundColor:'#1E272E'}}
              title={<div><b>Sucessfully published</b></div>}
              action={
                <div style={{width:120,height:32}} >
                  <div>Go to Home</div>
                </div>
              }
              classes={{title:'pick-master-scene',action:'publish-button'}}
            />
            <div style={{marginTop:'29px'}}>
            <iframe src={`https://www.revlity.com/vrplayer/${this.props.urlll}/`} style={{marginLeft:"30%"}} width="500px" height="350px" title="previeww"></iframe>
            <div style={{marginLeft:"30%",backgroundColor:'#1E272E',width:"500px",textAlign:"center",color:"white",height:'39px',display:'flex'}}>
              <div style={{paddingTop:'10px',flexGrow:1}}>https://www.revlity.com/vrplayer/{this.props.urlll}/</div>
              
              <CopyToClipboard style={{width:'47px',height:39,marginRight:"-5px"}}
                text={`https://www.revlity.com/vrplayer/${this.props.urlll}/`}
                onCopy={() => this.setState({open: true})}
                >
                {/* <img src={cpoyimg} width='47px' height='39px'/> */}
                <ButtonBase
                  focusRipple
                >
                <ClickAwayListener onClickAway={() => this.setState({open: false})}>
                  <div>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => this.setState({open: false})}
                      open={this.state.open}
                      // disableFocusListener
                      // disableHoverListener
                      disableTouchListener
                      title="Copied"
                    >
                      {/* <Button onClick={this.handleTooltipOpen}>Click</Button> */}
                      <img src={cpoyimg} width='47px' height='39px' alt="copy img"/>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
                </ButtonBase>
              </CopyToClipboard>
            </div>
            
            </div>
        </Card>
      </div>
    )
  }
}
