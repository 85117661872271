import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import apiRootUrl from "./api";
import { loginStatus, backendToken } from "./redux/actions";
import Login from "./components/login";
import NavBar from "./components/navbar";
import StudioBase from "./components/studio/studiobase";

// import Publishpage from './components/studio/publish';

class Routess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginauth: false
    };
  }

  // logincheck(){

    // }
    componentDidMount(){
    //   console.log("**********************routes**********************")
      let access_token = localStorage.getItem('accestoken');
      let headers = {
          'Authorization': `Bearer ${access_token}`,
          'Content-Type': 'application/json'
        }
        fetch(
            `${apiRootUrl}/api/checkuser/`, 
          {
            method:'GET',
            headers: headers
          }
        )
        .then((response)=>{
          if(response.ok){
            this.props.backendtokenDispatch(access_token)
            this.props.loginstatusDispatch(true)
          } else{
            this.props.loginstatusDispatch(false)
          }
        })
    }

  render() {
    let loginstatuss = this.props.loginstatus;
    //   let alluploadedimgs = this.props.alluploadedimgs
    return (
      <div>
        <Router>
            <Switch>
                
                {/* <Route exact path="/dashboard" render={() => (
                loginstatuss ? (
                    <Redirect to="/dashboard/"/>
                ) : (
                    <Login />
                )
                )}/> */}

                <Route path="/dashboard/"  component={
                    loginstatuss ? (
                        NavBar
                    ) : (
                        Login
                    )
                }/>
                {/* <Route path="/studio" render={() => (
                    loginstatuss?(
                        alluploadedimgs===null?(
                          <Redirect to="/dashboard"/>
                        ):<StudioBase/>
                    ):<Login/>
                )}
                /> */}

            <Route path="/studio" component={StudioBase} />
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginstatus: state.loginStatusReducer,
    alluploadedimgs: state.allUploadedImagesReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginstatusDispatch: status => dispatch(loginStatus(status)),
    backendtokenDispatch: token => dispatch(backendToken(token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routess);
