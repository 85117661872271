import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    Redirect
} from "react-router-dom";
import { uuidAction, allUploadImage, connectedData, projectName, creatorName } from '../redux/actions'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import apiRootUrl from '../api';
import cpoyimg from '../assests/copy.svg';

class ListProjects extends Component {
  constructor(props){
    super(props);
    this.state = {
      projectlist:'',draftprojects:[],redirecttostudio:false,
      open: false,  openid:''
    }
    // this.clickondraft = this.clickondraft.bind(this);
  }
  componentWillMount=()=>{
    let headers = {
      'Authorization': `Bearer ${this.props.backendtoken}`,
      'Content-Type': 'application/json'
    }

    fetch(`${apiRootUrl}/api/listprojects/`,{
      method: 'GET',
      headers: headers
    })
    .then(res => res.json())
    .then(res => {
      this.setState({projectlist:res})
    })

    // here replace with draft uuid api 
    fetch(`${apiRootUrl}/api/listuuiddraft/`,{
      method: 'GET',
      headers: headers
    })
    .then(res => res.json())
    .then(res => {
      this.setState({draftprojects:res})
    })
  }

  clickondraft=(val)=>{
    this.props.uuiddispatch(val.uuid)
    // let uuidd = this.props.uuid
    let headers = {
      'Authorization': `Bearer ${this.props.backendtoken}`,
      'Content-Type': 'application/json'
    }
    fetch( `${apiRootUrl}/api/editdraft/${val.uuid}/`,{
        method: 'GET',
        headers: headers
    })
    .then(res => res.json())
    .then(res => {
        let praseddraftdata =  JSON.parse(res.draftdata)
        let prasedconfigdata = JSON.parse(res.configdata)

        this.props.titledispatch(res.title);
        this.props.creatordispatch(res.creator);
        
        for (let index = 0; index < praseddraftdata.length; index++) {
          this.props.uploadimagedata(praseddraftdata[index])
        }

        if(prasedconfigdata){
          for (let i2 = 0; i2 < prasedconfigdata.length; i2++) {
            this.props.connectdatadispatch(prasedconfigdata[i2])     
          }
        }

        this.setState({redirecttostudio:true})
    })
    // console.log(val.draftdata)
    // let praseddata =  JSON.parse(val.draftdata)
    // console.log(praseddata)
    // for (let index = 0; index < praseddata.length; index++) {
    //   this.props.uploadimagedata(praseddata[index])
    //   // let url = baseurl/praseddata[index].bucketpath
    // }
    // this.setState({redirecttostudio:true})
  }

  render() {
    // console.log(this.state.redirecttostudio)
    if(this.state.redirecttostudio){
      return  <Redirect to="/studio"/>
    }
    const projectsList = this.state.projectlist
    // console.log(projectsList)
    const draftprojectss = this.state.draftprojects    
    return (
      <div style={{margin:'auto 11.5%',paddingBottom:'100px'}}>
        <Card style={{minHeight:'311px'}}>
            <CardHeader style={{height:'55px',backgroundColor:'#1E272E'}}
            title={<div><b>LIVE</b> Projects</div>}
            classes={{title:'live-projects-title'}}
            />
            
            {
              (projectsList.length===0)?(
                <CardContent>
                <Typography variant="title" noWrap={true} className='nothing-published-title'>
                    Nothing is published yet
                </Typography>
                </CardContent>
              ):(
                <Grid container spacing={40} style={{paddingTop:24,paddingBottom:43,paddingLeft:44,paddingRight:44}}>
                  <Grid item xs={12}>
                    <Grid container 
                    justify='space-between'
                     alignItems="center" spacing={40}>
                      {projectsList.map((val,i) => (
                        <Grid key={i} item style={{position:'relative'}}>
                          <Card>
                          <CardMedia style={{width:200,height:160,cursor:"pointer"}}
                            image={val.banner}
                            onClick={()=>window.open(`https://www.revlity.com/vrplayer/${val.uuid}/`, "_blank")}
                          />
                          
                          <CopyToClipboard style={{position:"absolute",top:"20px",right:"20px"}}
                            text={`https://www.revlity.com/vrplayer/${val.uuid}/`}
                            onCopy={() => this.setState({open: true, openid: i})}
                            >
                            <ButtonBase
                              focusRipple
                              key={i}
                            >
                            <ClickAwayListener onClickAway={() => this.setState({open: false})}>
                              <div>
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={() => this.setState({open: false})}
                                  open={this.state.openid===i?this.state.open:false}
                                  // disableFocusListener
                                  // disableHoverListener
                                  disableTouchListener
                                  title="Copied"
                                >
                                  {/* <Button onClick={this.handleTooltipOpen}>Click</Button> */}
                                  <img src={cpoyimg} width='26px' height='26px' alt="copy"/>
                                </Tooltip>
                              </div>
                            </ClickAwayListener>
                            </ButtonBase>
                          </CopyToClipboard>

                          </Card>
                          <Typography variant="subheading" noWrap={true} style={{marginTop:10,fontSize:16}} >{val.title}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
        </Card>
        <div style={{marginTop:'50px'}}>
          <div style={{fontSize:'22px',paddingBottom:'10px',borderBottom:'1px solid #808E9B'}}>Unpublished projects</div>
          <Grid container spacing={40} style={{paddingTop:24,paddingBottom:43,paddingLeft:44,paddingRight:44}}>
                  <Grid item xs={12}>
                    <Grid container justify='space-between' alignItems="center" spacing={40}>
                      {draftprojectss.map((val,i) => (
                        <Grid key={i} item>
                          <Paper style={{width:140,height:80}} onClick={()=>this.clickondraft(val)}/>
                          {/* <CardMedia style={{width:200,height:160}}
                            image={testimage}
                          /> */}
                          <Typography variant="subheading" noWrap={true} style={{marginTop:10,fontSize:16}}>{val.title}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
            </Grid>
        </div>
      </div>
    )
  };
};

const mapStateToProps = state => {
  return { 
    backendtoken: state.backendtokenReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      uploadimagedata: alluploadedimg => dispatch(allUploadImage(alluploadedimg)),
      connectdatadispatch: connectdata => dispatch(connectedData(connectdata)),
      uuiddispatch: uuid => dispatch(uuidAction(uuid)),
      titledispatch: title => dispatch(projectName(title)),
      creatordispatch: creator => dispatch(creatorName(creator))  
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ListProjects)