import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Redirect
} from "react-router-dom";
import { uuidAction, allUploadImage, connectedData } from "../../redux/actions";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
// import CardMedia from '@material-ui/core/CardMedia';
// import CardContent from '@material-ui/core/CardContent';
// import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
// import Paper from '@material-ui/core/Paper';
import { Input } from "semantic-ui-react";
import FineUploaderS3 from "fine-uploader-wrappers/s3";
import FileInput from "react-fine-uploader/file-input";
import publishButton from "../../assests/publish-button.svg";
import chooseButton from "../../assests/Button-choose.svg";
import bannerlogo from "../../assests/bannerlogo.png";
import logoo from "../../assests/rev-logo.png";
import Publishsucess from "./publishsucess";
import apiRootUrl from "../../api";

var functionbaseurl;

if (window.location.host === "create.revlity.com") {
  functionbaseurl = `https://izpb10ed0b.execute-api.ap-south-1.amazonaws.com/image-resizer/`;
} else {
  functionbaseurl = `https://izpb10ed0b.execute-api.ap-south-1.amazonaws.com/image-resizer/`;
  // functionbaseurl = `https://s499y6dh32.execute-api.ap-south-1.amazonaws.com/prod`;
}


class Publishpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.projectTitle,
      logoimg: "",
      bannerimg: bannerlogo,
      logourl: null,
      bannerurl: null,
      publishsucess: false,
      uploaderlogo: new FineUploaderS3({
        options: {
          credentials: {
            accessKey: "AKIAISKTJU7EFBXP7RYQ",
            expiration: "2022-12-23T10:26:00.996Z",
            secretKey: "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"
          },
          request: {
            endpoint: "https://revlity-old-source.s3.ap-south-1.amazonaws.com",
            accessKey: "AKIAISKTJU7EFBXP7RYQ"
          },
          signature: {
            endpoint: "https://s3.ap-south-1.amazonaws.com/revlity-old-source/",
            version: 3
          },
          retry: {
            enableAuto: false
          },
          chunking: {
            enabled: true,
            partSize: 200000000
          },
          objectProperties: {
            region: "ap-south-1"
          },
          callbacks: {
            onComplete: (id, name, response) => {
              // console.log("logo updated");
              let a1 = this.state.uploaderlogo.methods.getFile(id);
              if (window.Blob && a1 instanceof Blob) {
                var URL =
                  window.URL && window.URL.createObjectURL
                    ? window.URL
                    : window.webkitURL && window.webkitURL.createObjectURL
                      ? window.webkitURL
                      : null;
                if (!URL) {
                  throw Error(
                    "No createObjectURL function found to create blob url"
                  );
                }
                // let bucket = this.state.uploaderlogo.methods.getBucket(id)
                let path = this.state.uploaderlogo.methods.getKey(id);
                // let imgid = this.state.uploaderlogo.methods.getUuid(id)
                // let imgname = this.state.uploaderlogo.methods.getName(id)
                // let imgnamer = imgname.replace(/.jpg|.png|.jpeg/i,'')
                let imgblob = URL.createObjectURL(a1);
                let urll = `https://revlity-old-source.s3.ap-south-1.amazonaws.com/${path}`;
                this.setState({ logoimg: imgblob, logourl: urll });
              }
            }
          }
        }
      }),
      uploaderbanner: new FineUploaderS3({
        options: {
          credentials: {
            accessKey: "AKIAISKTJU7EFBXP7RYQ",
            expiration: "2022-12-23T10:26:00.996Z",
            secretKey: "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"
          },
          request: {
            endpoint: "https://revlity-old-source.s3.ap-south-1.amazonaws.com",
            accessKey: "AKIAISKTJU7EFBXP7RYQ"
          },
          signature: {
            endpoint: "https://s3.ap-south-1.amazonaws.com/revlity-old-source/",
            version: 3
          },
          retry: {
            enableAuto: false
          },
          chunking: {
            enabled: true,
            partSize: 200000000
          },
          objectProperties: {
            region: "ap-south-1"
          },
          callbacks: {
            onComplete: (id, name, response) => {
              let a1 = this.state.uploaderbanner.methods.getFile(id);
              if (window.Blob && a1 instanceof Blob) {
                var URL =
                  window.URL && window.URL.createObjectURL
                    ? window.URL
                    : window.webkitURL && window.webkitURL.createObjectURL
                      ? window.webkitURL
                      : null;
                if (!URL) {
                  throw Error(
                    "No createObjectURL function found to create blob url"
                  );
                }
                // let bucket = this.state.uploaderbanner.methods.getBucket(id)
                let path = this.state.uploaderbanner.methods.getKey(id);
                // let imgid = this.state.uploaderbanner.methods.getUuid(id)
                // let imgname = this.state.uploaderbanner.methods.getName(id)
                // let imgnamer = imgname.replace(/.jpg|.png|.jpeg/i,'')
                let imgblob = URL.createObjectURL(a1);
                let urll = `https://revlity-old-source.s3.ap-south-1.amazonaws.com/${path}`;
                this.setState({ bannerimg: imgblob, bannerurl: urll });
                // console.log("banner uploaded");
              }
            }
          }
        }
      })
    };
    this.titlename = this.titlename.bind(this);
    this.publishh = this.publishh.bind(this);
  }

  componentDidMount() { }

  resizelambda = () => {
    let categorydataa = this.props.catagorydata.categoryData
    let createname = this.props.creatorName;
    let projectname = this.state.title;
    for (let i = 0; i < categorydataa.length; i++) {
      const element = categorydataa[i];

      // depretatied   procede to delete
      // const lambdaurl = `https://7okozqi5d3.execute-api.ap-south-1.amazonaws.com/deploy?imgurl=${element.imagepath}&imguuid=${element.imageuuid}`
      // fetch(lambdaurl, {
      //   mode: 'no-cors'
      // })
      let functionurl = `${functionbaseurl}?imgurl=https://revlity-old-source.s3.ap-south-1.amazonaws.com/${element.imageuuid}&imguuid=${element.imageuuid}&creatorname=${createname}&projectname=${projectname}`
      fetch(functionurl, {
        mode: 'no-cors'
      })
    }
  }

  publishh() {
    this.resizelambda();
    // console.log("published")
    let connectdataa = this.props.connecteddata.connecteddata
    let categorydataa = this.props.catagorydata.categoryData
    let categorydata = JSON.stringify(categorydataa)
    let connectdata = JSON.stringify(connectdataa)
    const headers = {
      Authorization: `Bearer ${this.props.backendtoken}`,
      "Content-Type": "application/json"
    };
    let projectdata = JSON.stringify({
      uuid: this.props.uuid,
      title: this.state.title,
      creator: this.props.creatorName,
      logo: this.state.logourl,
      banner: this.state.bannerurl,
      player_data: connectdata,
      category_data: categorydata
    })
    fetch(`${apiRootUrl}/api/projectdata/`, {
      method: "POST",
      headers: headers,
      body: projectdata
    })
      .then(res => res.json())
      .then(res => {
        console.log(res);
        this.setState({ publishsucess: true });
      });
  }

  titlename(event) {
    this.setState({ title: event.target.value });
  }

  render() {
    let { logourl, bannerurl, publishsucess, bannerimg, logoimg } = this.state;
    if (!this.props.catagorydata) {
      return <Redirect to="/studio/category" />
    }
    return (
      <div
        style={{
          marginLeft: "11.5%",
          marginRight: "11.5%",
          backgroundColor: "white",
          paddingTop: "120px",
          paddingBottom: "100px"
        }}
      >
        {publishsucess ? (
          <Publishsucess urlll={this.props.uuid} />
        ) : (
            <Card style={{ height: "460px" }}>
              <CardHeader
                style={{ height: "55px", backgroundColor: "#1E272E" }}
                title={
                  <div>
                    <b>Review publishing</b>
                  </div>
                }
                action={
                  logourl !== null && bannerurl !== null ? (
                    <div
                      style={{ width: 120, height: 32 }}
                      onClick={this.publishh}
                    >
                      <img src={publishButton} alt="publish button" />
                    </div>
                  ) : null
                }
                classes={{ title: "pick-master-scene", action: "publish-button" }}
              />
              <Grid
                container
                style={{
                  paddingTop: 24,
                  paddingBottom: 43,
                  paddingLeft: 44,
                  paddingRight: 44
                }}
              >
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={40}
                  style={{ marginTop: 33 }}
                >
                  <Grid
                    xs={6}
                    item
                    style={{
                      height: 300,
                      width: 400,
                      flexBasis: "unset",
                      position: "relative",
                      padding: 0,
                      border: "0"
                    }}
                  >
                    {/* <div style={{backgroundImage:`url(${bannerimg})`,width:'100%',height:'100%',backgroundRepeat:"round"}}> */}
                    <img
                      src={bannerimg}
                      style={{ width: "100%", height: "100%" }}
                      alt="banner"
                    />
                    {logoimg !== "" ? (
                      <div
                        style={{
                          top: 25,
                          width: 72,
                          height: 27,
                          position: "absolute",
                          left: 165
                        }}
                      >
                        <img
                          src={logoimg}
                          style={{ width: "100%", height: "100%" }}
                          alt="logo"
                        />
                      </div>
                    ) : null}

                    <div
                      style={{
                        width: 304,
                        height: 43,
                        position: "absolute",
                        top: 123,
                        left: 54,
                        border: "1.5px solid",
                        background: "white",
                        textAlign: "center"
                      }}
                    >
                      <h2 style={{}}>{this.state.title}</h2>
                    </div>
                    <img
                      src={logoo}
                      style={{
                        width: 71,
                        height: 30,
                        position: "absolute",
                        bottom: 12,
                        right: 17
                      }}
                      alt="log"
                    />
                    {/* </div> */}
                    {/* {
                      (this.state.bannerurl===null)?(
                      <img src={bannerlogo} style={{width:"100%",height:'100%'}}/>
                      ):
                      <img src={this.state.bannerimg} style={{width:"100%",height:'100%'}}/>
                    } */}
                    {/* <h4 style={{left:90,top:70,position:"absolute",color:"white"}}>{this.state.title}</h4> */}
                    {/* <img style={{width:72,height:28,zIndex:10000,position:"absolute",left:"30%",top:"10%"}} src={this.state.logoimg}/> */}
                    {/* <img src={logoo} style={{width:75.5,height:30,position:'absolute',bottom:12,right:17,}}/> */}
                  </Grid>
                  <Grid xs={6} item style={{ height: 300 }}>
                    <h4>Cover information</h4>
                    <Input
                      defaultValue={this.state.title}
                      // placeholder={this.state.title}
                      onChange={this.titlename}
                    />
                    <div style={{ marginTop: 10, display: "flex" }}>
                      <div style={{ flexGrow: 1 }}>
                        <b>Logo </b> (100KB max .jpg .png)
                    </div>
                      <FileInput
                        style={{ display: "none" }}
                        accept="image/*"
                        type="file"
                        id="contained-button-file-logo"
                        uploader={this.state.uploaderlogo}
                      >
                        <div />
                      </FileInput>
                      <label htmlFor="contained-button-file-logo">
                        <img
                          width="90px"
                          height="23px"
                          style={{ cursor: "pointer" }}
                          src={chooseButton}
                          alt="choose button"
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: 10, display: "flex" }}>
                      <div style={{ flexGrow: 1 }}>
                        <b>Banner image </b> (200KB max .jpg .png)
                    </div>
                      <FileInput
                        style={{ display: "none" }}
                        accept="image/*"
                        type="file"
                        id="contained-button-file-banner"
                        uploader={this.state.uploaderbanner}
                      >
                        <div />
                      </FileInput>
                      <label htmlFor="contained-button-file-banner">
                        <img
                          width="90px"
                          height="23px"
                          style={{ cursor: "pointer" }}
                          src={chooseButton}
                          alt="btn"
                        />
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    backendtoken: state.backendtokenReducer,
    connecteddata: state.connectedDataReducer,
    catagorydata: state.categoryDataReducer,
    uuid: state.uuidReducer,
    projectTitle: state.projectNameReducer,
    creatorName: state.creatorNameReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadimagedata: alluploadedimg => dispatch(allUploadImage(alluploadedimg)),
    connectdatadispatch: connectdata => dispatch(connectedData(connectdata)),
    uuiddispatch: uuid => dispatch(uuidAction(uuid))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Publishpage);
