import * as THREE from 'three';
import defaultimg from '../../../assests/EMPTY-STATE.jpg';
import hotspotimgg from "../../../assests/hotspot-icon.svg";

const OrbitControls = require('three-orbitcontrols');


class AerialThree {
    constructor() {
        this.container = document.getElementById("aerial-container");
        this._camera = new THREE.PerspectiveCamera(70, this.container.offsetWidth / this.container.offsetHeight, 1, 1000)
        this._camera.position.set(0, 0, .1);
        this._camera.rotation.order = 'YXZ';

        this._scene = new THREE.Scene()

        this._renderer = new THREE.WebGLRenderer({ antialias: true, stencil: false });
        this._renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
        this.container.appendChild(this._renderer.domElement)

        this._controls = new OrbitControls(this._camera, this._renderer.domElement);
        // this._controls.maxPolarAngle = Math.PI / 2;
        // this._controls.minPolarAngle = Math.PI / 2;
        this._controls.enablePan = false;

        window.addEventListener('resize', this.onWindowResize.bind(this), false);

        this.animate();
        this.width = this.container.clientWidth;
        this.height = this.container.clientHeight;

        this.sphere();

        var hotspotmaterialmesh = new THREE.MeshBasicMaterial({
            map: new THREE.TextureLoader().load(hotspotimgg),
            side: THREE.DoubleSide,
            transparent: true
        });

        this.hotspotMeshMain = new THREE.Mesh(new THREE.PlaneGeometry(10, 10, 10), hotspotmaterialmesh);

        var materialdot = new THREE.MeshLambertMaterial({ map: new THREE.TextureLoader().load(hotspotimgg) });
        var geometrydot = new THREE.PlaneGeometry(15, 1.5, 1.5);
        var meshdot = new THREE.Mesh(materialdot, geometrydot);
        meshdot.position.set(0, 0, -80);
        this._camera.add(meshdot);
    }

    pointPosRot() {
        let hot = this.hotspotMeshMain.clone()

        var vectors = new THREE.Vector3();
        var quaternions = new THREE.Quaternion();
        let position = vectors.setFromMatrixPosition(this._camera.children[0].matrixWorld);
        let rotation = this._camera.children[0].getWorldQuaternion(quaternions)

        hot.setRotationFromQuaternion(rotation)
        hot.position.set(position.x, position.y, position.z)
        let pos = {
            x: hot.position.x,
            y: hot.position.y,
            z: hot.position.z
        }
        let rot = {
            x: hot.rotation._x, y: hot.rotation._y, z: hot.rotation._z
        }
        return ({ pos, rot })
    }

    generateHotspots(position, rotation, userdata) {
        let hot = this.hotspotMeshMain.clone()
        hot.position.set(position.x, position.y, position.z);
        hot.rotation.set(rotation.x, rotation.y, rotation.z);
        hot.scale.set(1, 1, 1)
        hot.userData.hotspotdata = userdata
        this._scene.children[0].children[0].add(hot)
    }

    sphere() {
        var material = new THREE.MeshBasicMaterial({
            map: new THREE.TextureLoader().load(defaultimg),
            side: THREE.BackSide,
        });
        var geometry = new THREE.SphereBufferGeometry(500, 60, 40);
        var sphere = new THREE.Mesh(geometry, material);
        sphere.scale.set(-1, 1, 1);
        var hotspotempty = new THREE.Object3D();
        hotspotempty.scale.set(-1, 1, 1);
        sphere.add(hotspotempty)
        this._scene.add(sphere)
    }

    changeSpheretexture(e) {
        var url = e
        if (!url) { url = defaultimg }
        this._scene.children[0].material.map.dispose();
        this._scene.children[0].material.map = new THREE.TextureLoader().load(url);
        this._scene.children[0].material.needsUpdate = true;
    }

    getRotation() {
        var azimuthal = this._controls.getAzimuthalAngle();
        var polar = this._controls.getPolarAngle()
        return ({ azimuthal, polar })
    }

    resetControls() {
        this._controls.reset();
    }

    onWindowResize() {
        this._camera.aspect = this.container.offsetWidth / this.container.offsetHeight
        this._camera.updateProjectionMatrix()
        this._renderer.setSize(this.container.offsetWidth, this.container.offsetHeight)
    }

    animate(timestamp) {
        requestAnimationFrame(this.animate.bind(this))
        this._renderer.render(this._scene, this._camera)
        this._controls.update();
    }

}

export default AerialThree;
