import * as THREE from "three";
import hotspotimgg from "../../assests/hotspotimg.png";
import ThreeScene from "./scene";


import defaultimg from '../../assests/EMPTY-STATE.jpg';


class CubeAdd extends ThreeScene {
  constructor() {
    super();
    // this.sceneData = sceneDataa;
    this.animate();
    this.width1 = this.container1.clientWidth;
    this.height1 = this.container1.clientHeight;

    this.width2 = this.container2.clientWidth;
    this.height2 = this.container2.clientHeight;

    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.loader = new THREE.TextureLoader()

    var hotspotmaterialmesh = new THREE.MeshBasicMaterial({
      map: new THREE.TextureLoader().load(hotspotimgg),
      side: THREE.DoubleSide,
      transparent: true
    });

    this.hotspotMeshMain = new THREE.Mesh(new THREE.PlaneGeometry(10, 10, 10),hotspotmaterialmesh);

    var materialdot1 = new THREE.MeshLambertMaterial({map: new THREE.TextureLoader().load(hotspotimgg)});
    var geometrydot1 = new THREE.PlaneGeometry(15, 1.5, 1.5);
    var meshdot1 = new THREE.Mesh(materialdot1, geometrydot1);
    meshdot1.position.set( 0, 0, -80 );
    this._camera1.add(meshdot1);

    var materialarrow1 = new THREE.MeshLambertMaterial({map: new THREE.TextureLoader().load(hotspotimgg)});
    var geometryarrow1 = new THREE.PlaneGeometry(15, 1.5, 1.5);
    var arrow1 = new THREE.Mesh(materialarrow1, geometryarrow1);
    arrow1.position.set( 0, 0, -40);
    this._camera1.add(arrow1);

    var materialdot2 = new THREE.MeshLambertMaterial({map: new THREE.TextureLoader().load(hotspotimgg)});
    var geometrydot2 = new THREE.PlaneGeometry(15, 1.5, 1.5);
    var meshdot2 = new THREE.Mesh(materialdot2, geometrydot2);
    meshdot2.position.set( 0, 0, 80 );
    this._camera2.add(meshdot2)

    var materialarrow2 = new THREE.MeshLambertMaterial({map: new THREE.TextureLoader().load(hotspotimgg)});
    var geometryarrow2 = new THREE.PlaneGeometry(15, 1.5, 1.5);
    var arrow2 = new THREE.Mesh(materialarrow2, geometryarrow2);
    arrow2.position.set( 0, 0, 40);
    this._camera2.add(arrow2);

    this.sphere1();
    this.sphere2();

    // this.container1.addEventListener( 'click', this.clickOnHotspot.bind(this), false );

  }


  clickOnHotspot(clientX,clientY){
    // console.log('SRR',THREE.Math.radToDeg(this._controls1.getAzimuthalAngle()))
    // console.log('TRR',THREE.Math.radToDeg(this._controls2.getAzimuthalAngle()))
    let canvasWidth = this.container1.clientWidth
    let canvasHeight = this.container1.clientHeight
    this.mouse.x = ((clientX-(this.container1.parentElement.offsetLeft+4)) / canvasWidth) * 2 - 1;
    this.mouse.y = -((clientY -(114+4))/ canvasHeight) * 2 + 1;
    this.raycaster.setFromCamera(this.mouse, this._camera1);
    var intersects = this.raycaster.intersectObjects(this._scene1.children[0].children[0].children);
    if (intersects.length) {
      // console.log(intersects)
      let targetuuid = intersects[0].object.userData.hotspotdata.targetuuid
      return targetuuid
    }
  } 

  sphere1(){
      var material1 = new THREE.MeshBasicMaterial( {
          map: new THREE.TextureLoader().load( defaultimg ),
          side: THREE.BackSide,
      });
      var geometry1 = new THREE.SphereBufferGeometry( 500, 60, 40 );
      var sphere1 = new THREE.Mesh(geometry1, material1);
      sphere1.scale.set(-1, 1, 1);
      var hotspotempty = new THREE.Object3D();
      hotspotempty.scale.set(-1,1,1);
      sphere1.add(hotspotempty)
      this._scene1.add(sphere1)

  }

  sphere2(){
      var material2 = new THREE.MeshBasicMaterial( {
          map: new THREE.TextureLoader().load( defaultimg ),
          side: THREE.BackSide,
      });
      var geometry2 = new THREE.SphereBufferGeometry( 500, 60, 40 );
      var sphere2 = new THREE.Mesh(geometry2, material2);
      sphere2.scale.set(-1, 1, 1);
      this._scene2.add(sphere2)
  }

  changeSphere1texture(e) {
      var url = e
      if(!url){url = defaultimg}
      this._scene1.children[0].material.map.dispose();
      this._scene1.children[0].material.map =  new THREE.TextureLoader().load( url );
      this._scene1.children[0].material.needsUpdate = true;
  }

  changeSphere2texture(e) {
      var url = e
      if(!url){url = defaultimg}
      this._scene2.children[0].material.map.dispose();
      this._scene2.children[0].material.map =  new THREE.TextureLoader().load( url );
      this._scene2.children[0].material.needsUpdate = true;
  }

  getRotation(){
    // var  heading1 = this._camera1.rotation.y;
    // var  radians1 = heading1 >= 0 ? heading1 : (2 * Math.PI) + heading1;
    var  rotationSouce = this._controls1.getAzimuthalAngle();

    // var  heading2 = this._camera2.rotation.y;
    // var  radians2 = heading2 >= 0 ? heading2 : (2 * Math.PI) + heading2;
    var  rotationTarget = this._controls2.getAzimuthalAngle();

    // console.log('SRR',THREE.Math.radToDeg(this._controls1.getAzimuthalAngle()))
    // console.log('TRR',THREE.Math.radToDeg(this._controls2.getAzimuthalAngle()))
    return ({rotationSouce,rotationTarget})
  }

  // this will give source hotspot values position and rotation
  getHotspotSource(){
    let hot = this.hotspotMeshMain.clone()

    var vectors = new THREE.Vector3();
    var quaternions = new THREE.Quaternion();
    let position = vectors.setFromMatrixPosition( this._camera1.children[0].matrixWorld );
    let rotation = this._camera1.children[0].getWorldQuaternion(quaternions)

    hot.setRotationFromQuaternion(rotation)
    hot.position.set(position.x, position.y, position.z)
    let positionreturn = {
      x: hot.position.x,
      y: hot.position.y,
      z: hot.position.z
    }
    let rotationreturn = {
      x:hot.rotation._x,y:hot.rotation._y,z:hot.rotation._z
    }

    var quaternionsA = new THREE.Quaternion();
    var vectorsA = new THREE.Vector3();
    let rotArrow = this._camera1.children[1].getWorldQuaternion(quaternionsA);
    let posArrow = vectorsA.setFromMatrixPosition( this._camera1.children[1].matrixWorld );

    return ({position:positionreturn,rotation:rotationreturn, arrowpos:posArrow, arrowrot:rotArrow})
  }

  // this wiil give return hotspot values position and rotation
  getHotspotReturn(){
    let hot = this.hotspotMeshMain.clone()

    var vectors = new THREE.Vector3();
    var quaternions = new THREE.Quaternion();
    let position = vectors.setFromMatrixPosition( this._camera2.children[0].matrixWorld );
    let rotation = this._camera2.children[0].getWorldQuaternion(quaternions)

    hot.setRotationFromQuaternion(rotation)
    hot.position.set(position.x, position.y, position.z)
    let positionreturn = {
      x: hot.position.x,
      y: hot.position.y,
      z: hot.position.z
    }
    let rotationreturn = {
      x:hot.rotation._x,y:hot.rotation._y,z:hot.rotation._z
    }

    var quaternionsA = new THREE.Quaternion();
    var vectorsA = new THREE.Vector3();
    let rotArrow = this._camera2.children[1].getWorldQuaternion(quaternionsA);
    let posArrow = vectorsA.setFromMatrixPosition( this._camera2.children[1].matrixWorld );
    return ({position:positionreturn,rotation:rotationreturn, arrowpos:posArrow, arrowrot:rotArrow})
  }

  generateHotspots(position,rotation,userdata){
    let hot = this.hotspotMeshMain.clone()
    hot.position.set(position.x, position.y, position.z);
    hot.rotation.set(rotation.x, rotation.y, rotation.z);
    hot.scale.set(2,2,2)
    hot.userData.hotspotdata = userdata
    this._scene1.children[0].children[0].add(hot)
  }

  clearOldHotspots(){
    let x = this._scene1.children[0].children[0].children.length
    for (let i = 0; i < x; i++) {
      this._scene1.children[0].children[0].remove(this._scene1.children[0].children[0].children[i]);
      

      // this._scene1.children[0].children[0].children[i].geometry.dispose();
      // this._scene1.children[0].children[0].children[i].material.dispose();
    }
    this._scene1.children[0].children[0].children = []
    
  }



  resetControls(){
    this._controls1.reset();
    this._controls2.reset();
  }

  // clickOnHotspots(event) {
  //   this.mouse.x = (event.clientX / this.width) * 2 - 1;
  //   this.mouse.y = -(event.clientY / this.height) * 2 + 1;
  //   this.raycaster.setFromCamera(this.mouse, this._camera1);
  //   if (this._scene1.children[0].children[0]) {
  //     var intersects = this.raycaster.intersectObjects(this._scene1.children[0].children[0].children);
  //     if (intersects.length) {
  //       console.log("CLICKED ON HOTSPOT")
  //     }
  //   }
  // }

  // removeHotspots(id) {
  //   if(this._scene.children[0].children[0]){
  //     let lenofhotspots = this._scene.children[0].children[0].children;
  //     for (let index = 0; index < lenofhotspots.length; index++) {
  //       this._scene.children[0].children[0].children[index].geometry.dispose();
  //       this._scene.children[0].children[0].children[index].material.dispose();
  //       this._scene.children[0].children[0].remove(
  //         this._scene.children[0].children[0].children[index]
  //       );
  //     }
  //     this._scene.children[0].remove(this._scene.children[0].children[0]);
  //   }
  // }


}

export default CubeAdd;
