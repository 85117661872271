import { combineReducers } from 'redux';
import defaultimage from '../assests/default.jpg'

import {
    LOGIN_STATUS,
    BACKEND_TOKEN,
    ALL_UPLOADED_IMAGE,
    SELECTED_IMAGE,
    SOURCE_IMAGE_DATA,
    TARGET_IMAGE_DATA,
    SHOULD_IMAGE_UPDATE,
    CLICKED_ON_BOTTOM_IMG,
    HOTSPOT_SINGLE,
    HOTSPOTS_TO_ALL_UPLOADED,
    DELETE_HOTSPOT,
    EDIT_HOTSPOT,
    ADD_HOTSPOT_MAIN_SCENE,
    DELETE_HOTSPOT_MAIN_SCENE,
    EDIT_HOTSPOT_MAIN_SCENE,
    CONNECTED_DATA,
    UUID,
    PROJECT_NAME,
    CREATOR_NAME,
    CATEGORY_DATA,
    DELETE_CATEGORY_DATA_ITEM,
    SELECTED_AERIAL_IMAGE
} from './actions-types';

import update from 'react-addons-update'

function loginStatusReducer(state = false, action) {
    switch (action.type) {
      case LOGIN_STATUS:
        return action.status
      default:
        return state
    }
}

function projectNameReducer(state = '', action) {
  switch (action.type) {
    case PROJECT_NAME:
      return action.title
    default:
      return state
  }
}

function creatorNameReducer(state = '', action) {
  switch (action.type) {
    case CREATOR_NAME:
      return action.creator
    default:
      return state
  }
}

function backendtokenReducer(state = null, action) {
    switch (action.type) {
      case BACKEND_TOKEN:
        return action.token
      default:
        return state
    }
}

function allUploadedImagesReducer(state = null, action) {
  switch (action.type)  {
    case ALL_UPLOADED_IMAGE:
      if(state===null){
        return {...state, alluploadedimg:[action.imgdata] }
      }
      return {...state, alluploadedimg: [...state.alluploadedimg,action.imgdata] }
      // return {...state, alluploadedimg: action.imgdata }
    case HOTSPOTS_TO_ALL_UPLOADED:
      let indexs = state.alluploadedimg.findIndex(x => x.uuid===action.hotspots.sourceuuid)
      let indexr = state.alluploadedimg.findIndex(x => x.uuid===action.hotspots.targetuuid)
      let source = action.hotspotr.sourceuuid
      let target = action.hotspotr.targetuuid
      action.hotspotr.sourceuuid = target
      action.hotspotr.targetuuid = source
      return update(
        state,{
          alluploadedimg:{
            [indexs]:{
              hotspots:{$push : [ action.hotspots ]}
            },
            [indexr]:{
              hotspots:{$push : [ action.hotspotr ]}
            }
        }}
      )
    case DELETE_HOTSPOT:
      let indexds = state.alluploadedimg.findIndex(x => x.uuid===action.sourceuuid)
      let indexdh = state.alluploadedimg[indexds].hotspots.findIndex(y=>y.targetuuid===action.targetuuid)
      return update(
        state,{
          alluploadedimg:{
            [indexds]:{
              hotspots:{
                $splice : [[indexdh,1]]
              }
            }
          }
        }
      )
    case EDIT_HOTSPOT:
      let indexes = state.alluploadedimg.findIndex(x => x.uuid===action.sourceuuid)
      let indexeh = state.alluploadedimg[indexes].hotspots.findIndex(y=>y.targetuuid===action.targetuuid)
      return update(
        state,{
          alluploadedimg:{
            [indexes]:{
              hotspots:{
                [indexeh]:{$set:action.editeddata}
              }
            }
          }
        }
      )
    default:
      return state;
  }
}

function selectedImageReducer(state = null, action) {
  switch (action.type) {
    case SELECTED_IMAGE:
      return action.selectedimg
    default:
      return state
  }
}

const sdata =  {
  blob: defaultimage,
  name: 'Please Select image'
}
function sourceImageDataReducer(state = sdata, action) {
  switch (action.type) {
    case SOURCE_IMAGE_DATA:
      return action.sourceimgdata
    default:
      return state
  }
}

const tdata =  {
  blob: defaultimage,
  name: 'Please Select image'
}
function targetImageDataReducer(state = tdata, action) {
  switch (action.type) {
    case TARGET_IMAGE_DATA:
      return action.targetimgdata
    default:
      return state
  }
}

function shouldimgUpdateReducer(state = false, action) {
  switch (action.type) {
    case SHOULD_IMAGE_UPDATE:
      return action.imgupdate
    default:
      return state
  }
}

function clickedOnBottomImgReducer(state = false, action) {
  switch (action.type) {
    case CLICKED_ON_BOTTOM_IMG:
      return action.imgclick
    default:
      return state
  }
}

function hotspotSingleReducer(state = null, action) {
  switch (action.type) {
    case HOTSPOT_SINGLE:
      return action.hotspot
    default:
      return state
  }
}

function uuidReducer(state = null, action) {
  switch (action.type) {
    case UUID:
      return action.uuid
    default:
      return state
  }
}

const connectedDataReducer = (state = null, action) => {
  switch (action.type) {
    case CONNECTED_DATA:
      if(state===null){
        return {...state, connecteddata:[action.connect] }
      }
      return {...state, connecteddata: [...state.connecteddata,action.connect] }
    case ADD_HOTSPOT_MAIN_SCENE:
      let indexx = state.connecteddata.findIndex(x => x.uuid===action.uuid)
      return update(
        state,{
        connecteddata:{
          [indexx]:{
            hotspots:{$push : [ action.hotspot ]}
          }
        }}
      )
    case EDIT_HOTSPOT_MAIN_SCENE:
      let indexem = state.connecteddata.findIndex(x => x.uuid===action.uuid)
      let indexemh = state.connecteddata[indexem].hotspots.findIndex(y=>y.target===action.editeddata.target)
      return update(
        state,{
          connecteddata:{
            [indexem]:{
              hotspots:{
                [indexemh]:{$set:action.editeddata}
              }
            }
          }
        }
      )
    case DELETE_HOTSPOT_MAIN_SCENE:
      let indexdm = state.connecteddata.findIndex(x => x.uuid===action.sourceuuid)
      let indexdms = state.connecteddata[indexdm].hotspots.findIndex(y=>y.targetuuid===action.targetuuid)
      return update(
        state,{
          connecteddata:{
            [indexdm]:{
              hotspots:{
                $splice : [[indexdms,1]]
              }
            }
          }
        }
      )
    default:
      return state
  }
}

// by shafu-dev
function categoryDataReducer(state = null, action) {
  switch (action.type)  {
    case CATEGORY_DATA:
      if(state===null){
        return {...state, categoryData:[ action.data ] }
      }
      return {...state, categoryData: [...state.categoryData,action.data] }
    case DELETE_CATEGORY_DATA_ITEM:
      return update(
        state,{
          categoryData:{
            $splice : [[action.item,1]]
          }
        }
      )
    default:
      return state;
  }
}


// aerial 

const aerialimage =  {
  blob: defaultimage,
  name: 'Please Select image'
}
function selectedAreialImageReducer(state = aerialimage, action) {
  switch (action.type) {
    case SELECTED_AERIAL_IMAGE:
      return action.selectedimg
    default:
      return state
  }
}


const reducer = combineReducers({
    loginStatusReducer,
    backendtokenReducer,
    allUploadedImagesReducer,
    selectedImageReducer,
    sourceImageDataReducer,
    targetImageDataReducer,
    shouldimgUpdateReducer,
    clickedOnBottomImgReducer,
    hotspotSingleReducer,
    connectedDataReducer,
    uuidReducer,
    projectNameReducer,
    creatorNameReducer,
    categoryDataReducer,
    selectedAreialImageReducer
})

export default reducer;