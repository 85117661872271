import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Redirect
} from "react-router-dom";
import {
  sourceImgData,
  targetImgData,
  clickedOnBottomImg
} from "../../redux/actions";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
// import { Search } from "semantic-ui-react";
import { Grid as SGrid } from "semantic-ui-react";
// import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
// import _ from "lodash";
// import faker from "faker";
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { Image } from "semantic-ui-react";
import { Button } from 'semantic-ui-react'
import hotspoticon from "../../assests/hotspot-icon.svg";

// const source = _.times(5, () => ({
//   title: faker.company.companyName(),
//   description: faker.company.catchPhrase(),
//   image: faker.internet.avatar(),
//   price: faker.finance.amount(0, 100, 2, "$")
// }));

class BottomTray extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecttoupload: false
    }

    this.imgdata = this.imgdata.bind(this);
  }

  // componentWillMount() {
  //   this.resetComponent();
  // }

  // resetComponent = () =>
  //   this.setState({ isLoading: false, results: [], value: "" });

  // handleResultSelect = (e, { result }) =>
  //   this.setState({ value: result.title });

  // handleSearchChange = (e, { value }) => {
  //   this.setState({ isLoading: true, value });

  //   setTimeout(() => {
  //     if (this.state.value.length < 1) return this.resetComponent();

  //     const re = new RegExp(_.escapeRegExp(this.state.value), "i");
  //     const isMatch = result => re.test(result.title);

  //     this.setState({
  //       isLoading: false,
  //       results: _.filter(source, isMatch)
  //     });
  //   }, 300);
  // };

  imgdata(data) {
    if (this.props.imageupdate === true) {
      this.props.clickdonbottomimgdispatch(true);
      if (this.props.selectedimage === "source") {
        this.props.sourceimgdatadispatch(data);
      } else if (this.props.selectedimage === "target") {
        this.props.targetimgdatadispatch(data);
      }
    }
  }

  render() {
    // const { isLoading, value, results } = this.state;
    const allimages = this.props.alluploadedimgs;
    if (!allimages) {
      window.location.href = '/dashboard/'
    }
    if (this.state.redirecttoupload) {
      return <Redirect to="/dashboard/upload" />
    }
    const sourceimage = this.props.sourceimage.uuid;
    return (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          overflow: "hidden"
        }}
      >
        <Card style={{ height: 178 }}>
          <CardHeader
            style={{
              height: "30px",
              backgroundColor: "rgba(255, 206, 1, 1)"
            }}
            title="Image tray"
            classes={{ title: "bottom-tray-title" }}
            action={
              <SGrid>
                <SGrid.Column width={6}>
                  <Button primary onClick={() => this.setState({ redirecttoupload: true })}>Upload</Button>
                  {/* <Search
                loading={isLoading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                results={results}
                value={value}
                // {...this.props}
            /> */}
                </SGrid.Column>
              </SGrid>
            }
          />

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              margin: "14px 0 14px 32px"
            }}
          >
            <GridList
              cellHeight={150}
              style={{
                height: 150,
                margin: 0,
                width: "100%",
                paddingBottom: "50px"
              }}
              spacing={16}
            >
              {allimages.alluploadedimg.map((x, i) => (
                <GridListTile
                  key={i}
                  style={{
                    padding: 0,
                    width: 200,
                    height: 100,
                    marginRight: "2%",
                    marginTop: 6
                  }}
                >
                  <img
                    width="200px"
                    height="100px"
                    style={
                      sourceimage === x.uuid
                        ? { opacity: 0.4 }
                        : { cursor: "pointer" }
                    }
                    src={`https://revlity-thumbnail360.s3.ap-south-1.amazonaws.com/thumbnails/${
                      x.bucketpath
                      }.jpg`}
                    onClick={() =>
                      sourceimage !== x.uuid ? this.imgdata(x) : null
                    }
                    alt={x.name}
                  />
                  <GridListTileBar
                    style={{
                      height: 30,
                      background:
                        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " +
                        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
                    }}
                    title={x.name}
                  />
                  {x.hotspots.length !== 0 ? (
                    <GridListTileBar
                      style={{
                        height: 30,
                        background:
                          "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                          "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
                      }}
                      title={x.hotspots.length}
                      titlePosition="top"
                      actionIcon={
                        <Image src={hotspoticon} width="16px" height="16px" />
                      }
                      actionPosition="right"
                      classes={{
                        title: "list-no-hotspots",
                        actionIcon: "hotspot-icon-bottom"
                      }}
                    />
                  ) : null}
                </GridListTile>
              ))}
            </GridList>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    alluploadedimgs: state.allUploadedImagesReducer,
    imageupdate: state.shouldimgUpdateReducer,
    selectedimage: state.selectedImageReducer,
    sourceimage: state.sourceImageDataReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sourceimgdatadispatch: sourceimgdata =>
      dispatch(sourceImgData(sourceimgdata)),
    targetimgdatadispatch: targetimgdata =>
      dispatch(targetImgData(targetimgdata)),
    clickdonbottomimgdispatch: imgclick =>
      dispatch(clickedOnBottomImg(imgclick))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BottomTray);
