import React, { Component } from "react";
import { connect } from "react-redux";
import {
  // BrowserRouter as Router,
  Route,
  // Link,
  NavLink,
  Switch,
  Redirect
} from "react-router-dom";
import { uuidAction, allUploadImage } from "../../redux/actions";
import shortid from "shortid";
// import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
// import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Tab, Menu } from "semantic-ui-react";
import {
  Modal
  //  Embed
} from "semantic-ui-react";
import StudioMain from "./studiomain";
import Publishpage from "./publish";
import Category from "./category";
import BottomTray from "./bottomtray";
import logoo from "../../assests/rev-logo.png";
import studioIcn from "../../assests/studioIcn.svg";
import publishIcn from "../../assests/publishIcn.svg";
import categoryIcn from "../../assests/categoryIcn.svg";
// import logoo from '../../assests/LogoB.png';
import logopaly from "../../assests/Play.svg";
import logosignout from "../../assests/logout.svg";
import "../styles/navbarstudio.css";
import apiRootUrl from "../../api";

import { Image } from "semantic-ui-react";
import Areial from "./aerial/areial";

const Studiopage = () => {
  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 130,
          bottom: 200,
          overflow: "auto",
          width: "100vw",
          minHeight: 280,
          minWidth: 374
        }}
      >
        <StudioMain />
      </div>

      <div style={{ position: "fixed", bottom: 0, width: "100vw" }}>
        <BottomTray />
      </div>
    </div>
  );
};

var functionbaseurl;

if (window.location.host === "create.revlity.com") {
  functionbaseurl = `https://omffi4o68i.execute-api.ap-south-1.amazonaws.com/prod/`;

} else {
  // functionbaseurl = `https://fgikxzl6oe.execute-api.ap-south-1.amazonaws.com/prod`;
  functionbaseurl = `https://omffi4o68i.execute-api.ap-south-1.amazonaws.com/prod/`;
}


class StudioBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderstudio: false,
      activeIndex: 0
    };
    this.handlelogout = this.handlelogout.bind(this);
  }

  panes = [
    {
      menuItem: (
        <Menu.Item key="studio">
          <img src={studioIcn} width="14px" height="14px" alt="logoo" /> Studio
        </Menu.Item>
      ),
      render: () => <Redirect to="/studio" />
    },
    {
      menuItem: (
        <Menu.Item key="category">
          <img src={categoryIcn} width="14px" height="14px" alt="logoo" />{" "}
          Category
        </Menu.Item>
      ),
      render: () => <Redirect to="/studio/category" />
    },

    {
      menuItem: (
        <Menu.Item key="aeiral">
          <img src={categoryIcn} width="14px" height="14px" alt="logoo" />{" "}
          aeiral
        </Menu.Item>
      ),
      render: () => <Redirect to="/studio/aerial" />
    },

    {
      menuItem: (
        <Menu.Item key="publish">
          <img src={publishIcn} width="14px" height="14px" alt="logoo" />{" "}
          Publish
        </Menu.Item>
      ),
      render: () => <Redirect to="/studio/publish" />
    }
  ];

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  triggerLambdaFunctions() {
    let allimages = this.props.alluploadedimgs;
    if (!allimages) {
      // window.location.href = 'https://create.revlity.com/dashboard/';
    }
    for (let i = 0; i < allimages.alluploadedimg.length; i++) {

      let creatorname = this.props.creatorName;
      let projectname = this.props.projectTitle
      let imageuuid = allimages.alluploadedimg[i].uuid;

      let idd = allimages.alluploadedimg[i].uuid;
      let image = `https://revlity-old-source.s3.ap-south-1.amazonaws.com/${
        allimages.alluploadedimg[i].bucketpath
        } `;

      if (window.location.host === "create.revlity.com") {
        // let functionurl = `https://j6dqsi4ycb.execute-api.ap-south-1.amazonaws.com/prod/?imgurl=${image}&imguuid=${imagedata}`;
      } else {

      }

      let functionurl = `${functionbaseurl}/?imgurl=${image}&imguuid=${imageuuid}&creatorname=${creatorname}&projectname=${projectname}`
      fetch(functionurl, {
        mode: 'no-cors'
      })
    }
  }

  componentDidMount() {
    this.triggerLambdaFunctions();
    const headers = {
      Authorization: `Bearer ${this.props.backendtoken}`,
      "Content-Type": "application/json"
    };
    if (this.props.uuid == null) {
      const uuid = shortid.generate();
      let title = this.props.projectTitle;
      let creator = this.props.creatorName;
      let draftdataa = this.props.alluploadedimgs.alluploadedimg;
      let x = JSON.stringify(draftdataa);
      let drafttbody = JSON.stringify({
        uuid: uuid,
        title: title,
        creator: creator,
        draftdata: x,
        configdata: "null"
      });

      fetch(`${apiRootUrl}/api/postdraft/`, {
        method: "POST",
        headers: headers,
        body: drafttbody
      })
        .then(res => res.json())
        .then(res => {
          //   console.log(res.uuid);
          this.props.uuiddispatch(res.uuid);
          this.setState({ renderstudio: true });
        });
    } else {
      this.setState({ renderstudio: true });
    }
  }

  handlelogout() {
    window.location.href = 'https://create.revlity.com/dashboard/';
    // let bodyy = {
    //   client_id: "QjbQ7PyHXMzUa7Ab2NsoSgn5uyM2vAJYEYnmbtnA",
    //   client_secret:
    //     "kjTk37Bhz8W1pQdANVgxSbu2Stzcvi77to3xbhvYwDTxyNGdr3sgMCxGj1CqTc4wZFRGfNBe89PjI8T2lgQpql4PJKVMOko3wn3JHXIYBhmftTJglKiMRc9wqBUkqEdj",
    //   token: this.props.backendtoken
    // };
    // fetch(`${apiRootUrl}/auth/revoke-token/`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify(bodyy)
    // }).then(res => {
    //   if (res.ok) {
    //     localStorage.removeItem("accestoken");
    //     window.location.href = apiRootUrl;
    //   }
    // });
  }

  render() {
    return (
      <div
        style={{ height: "100vh", width: "100vw", backgroundColor: "white" }}
      >
        <div style={{ position: "fixed", top: 0, height: 90, width: "100vw" }}>
          <AppBar
            style={{
              position: "fixed",
              backgroundColor: "#FFFFFF",
              boxShadow: "none"
            }}
          >
            <Toolbar
              style={{
                paddingLeft: "3.4%",
                paddingRight: "3.4%",
                display: "flex",
                alignItems: "center"
              }}
            >
              {/* <NavLink to="/dashboard">
                <Button
                  variant="contained"
                  style={{
                    width: 105,
                    height: 44,
                    padding: 0,
                    backgroundColor: "#FFFFFF",
                    boxShadow: "none"
                  }}
                >
                  <Image src={logoo} width="105px" height="44px" />
                </Button>
              </NavLink> */}
              <Typography
                variant="title"
                noWrap={true}
                style={{
                  fontWeight: "bold",
                  paddingRight: "30px"
                }}
              >
                {this.props.projectTitle}
              </Typography>
              {this.props.connecteddata !== null ? (''
                // <Modal
                //   style={{ width: "80%", height: "80%" }}
                //   trigger={
                //     <Button
                //       variant="fab"
                //       style={{
                //         marginRight: "3%",
                //         width: 40,
                //         height: 40,
                //         boxShadow: "none"
                //       }}
                //       onClick={this.clickonpreview}
                //     >
                //       <Image src={logopaly} width="40px" height="40px" />
                //     </Button>
                //   }
                //   closeOnDimmerClick={false}
                //   closeIcon
                // >
                //   <iframe
                //     src={`https://beta.revlity.com/vrpreview/${
                //       this.props.uuid
                //     }/`}
                //     width="100%"
                //     height="100%"
                //     title="preview"
                //   />
                // </Modal>
              ) : null}

              {/* <Button variant="fab"  style={{width:48,height:48,boxShadow:'none',bottom:'-10px'}}>
                        <Image src={logosignout} width='48px' height='48px'/>
                    </Button> */}
              <div style={{ flexGrow: 1 }} />
              <Button
                variant="contained"
                style={{
                  display: "block",
                  width: 86,
                  height: 24,
                  padding: "0",
                  boxShadow: "none",
                  backgroundColor: "#FFFFFF",
                  textTransform: "capitalize"
                }}
                onClick={this.handlelogout}
              >
                {/* <Image src={logosignout} width="86px" height="24px" /> */}
                Close
              </Button>
            </Toolbar>
          </AppBar>
        </div>
        <div
          style={{
            position: "fixed",
            marginTop: "64px",
            width: "100%"
          }}
        >
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={this.panes}
            style={{
              paddingLeft: "10px",
              backgroundColor: "rgba(30, 39, 46, 0.03)"
            }}
            onTabChange={this.handleTabChange}
          />
        </div>
        <Switch>
          <Route exact path="/studio/aerial" component={Areial} />
          <Route exact path="/studio/category" component={Category} />
          <Route exact path="/studio/publish" component={Publishpage} />
          <Route exact path="/studio" component={Studiopage} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    backendtoken: state.backendtokenReducer,
    alluploadedimgs: state.allUploadedImagesReducer,
    connecteddata: state.connectedDataReducer,
    uuid: state.uuidReducer,
    projectTitle: state.projectNameReducer,
    creatorName: state.creatorNameReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadimagedata: alluploadedimg => dispatch(allUploadImage(alluploadedimg)),
    uuiddispatch: uuid => dispatch(uuidAction(uuid))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudioBase);
