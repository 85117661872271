import React, { Component } from 'react'

import { connect } from 'react-redux';

import {
    selectedImageAerial
} from "../../../redux/actions";

import Card from "@material-ui/core/Card";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { Image } from "semantic-ui-react";

class Listimages extends Component {
    clickonimage = (data) => {
        this.props.aerialimgdatadispatch(data);
    }
    render() {
        const allimages = this.props.alluploadedimgs
        return (
            <Card style={{ height: 320 }}>

                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                        margin: "14px 0 14px 32px"
                    }}
                >
                    <GridList
                        cellHeight={300}
                        style={{
                            height: 300,
                            margin: 0,
                            width: "100%",
                            paddingBottom: "50px"
                        }}
                        spacing={16}
                    >
                        {allimages.alluploadedimg.map((x, i) => (
                            <GridListTile
                                key={i}
                                style={{
                                    padding: 0,
                                    width: 200,
                                    height: 100,
                                    marginRight: "2%",
                                    marginTop: 6
                                }}
                            >
                                <img
                                    width="200px"
                                    height="100px"
                                    style={{ cursor: "pointer" }}
                                    src={`https://revlity-thumbnail360.s3.ap-south-1.amazonaws.com/thumbnails/${
                                        x.bucketpath
                                        }.jpg`}
                                    onClick={() => this.clickonimage(x)}
                                    alt={x.name}
                                />

                            </GridListTile>
                        ))}
                    </GridList>
                </div>
            </Card>
        )
    }
}


const mapStateToProps = state => {
    return {
        alluploadedimgs: state.allUploadedImagesReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        aerialimgdatadispatch: selectedimg =>
            dispatch(selectedImageAerial(selectedimg))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listimages);