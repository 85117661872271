import React, { Component } from 'react';

import { connect } from 'react-redux';

import update from 'react-addons-update';

// import {
//     selectedImageAerial
// } from "../../redux/actions";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Selectimages from './selectimages';

import AerialThree from './aerialThree';

import lineonthreejs from "../../../assests/linesonthreejs.png";
import Selectpoint from './selectpoint';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


class Areial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgrotation: null,
            gridliness: false,
            selectedpoint: null,
            allPoints: [],
        }
        this.AerialT = "";
    }

    componentDidMount() {
        this.AerialT = new AerialThree();
    }

    componentDidUpdate(prevProps) {
        if (this.props.aerialImage !== prevProps.aerialImage) {
            let imgg11 = this.props.aerialImage.bucketpath;
            let imgg1 = `https://revlity-old-source.s3.ap-south-1.amazonaws.com/${imgg11}`;
            this.AerialT.changeSpheretexture(imgg1);
            this.AerialT.resetControls();
        }
    }

    getrotation = () => {
        let rotation = this.AerialT.getRotation();
        console.log(rotation);
        this.setState({ imgrotation: rotation })
    }

    selectpointimage = (x) => {
        this.setState({ gridliness: true })
        let selectedpoint = {
            text: x.name,
            sceneId: x.bucketpath
        }
        this.setState({ selectedpoint })
    }

    addPoint = () => {
        let point = this.AerialT.pointPosRot();
        console.log(point);
        this.AerialT.generateHotspots(point.pos, point.rot, 'ww')
        let addedpoint = {
            sceneId: this.state.selectedpoint.sceneId,
            text: this.state.selectedpoint.text,
            position: point.pos,
            rotation: point.rot
        }
        let updateallpoints = update(this.state.allPoints, { $push: [addedpoint] });

        this.setState({
            selectedpoint: null,
            gridliness: false,
            allPoints: updateallpoints
        })
    }

    render() {
        const { gridliness, imgrotation, selectedpoint, allPoints } = this.state

        return (
            <div style={{
                position: "fixed",
                top: 130,
                bottom: 200,
                overflow: "auto",
                width: "100vw",
                minHeight: 280,
                minWidth: 374,
                marginLeft: 10
            }}>
                <Grid item xs={12} style={{ height: "100%" }}>
                    <Grid
                        style={{ height: "100%" }}
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid
                            id="grid1"
                            item
                            style={{ height: "100%", width: "40%", position: "relative" }}
                        >
                            <div
                                id="aerial-container"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    border: "4px solid #B9C0C7"
                                }}
                            />
                            {gridliness && (
                                <div
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        position: "absolute",
                                        zIndex: 100,
                                        top: 4,
                                        pointerEvents: "none"
                                    }}
                                >
                                    <img
                                        src={lineonthreejs}
                                        width="100%"
                                        height="100%"
                                        alt="lines on three"
                                    />
                                </div>
                            )}
                        </Grid>

                        <Grid
                            id="grid2"
                            item
                            style={{ height: "100%", width: "40%", position: "relative" }}
                        >
                            <Selectimages />
                            {(this.props.aerialImage.name !== 'Please Select image') && "IMAGE SELECTED"}<br /><br />


                            <div
                                style={{
                                    display: "flex",
                                    height: "30px",
                                    // justifyContent: "space-between"
                                }}
                            >
                                {(this.props.aerialImage.name !== 'Please Select image') && (
                                    <Button onClick={() => this.getrotation()}>set rotation</Button>
                                )}
                                {imgrotation && "image rotation locked "}
                            </div> <br /><br />

                            <div
                                style={{
                                    display: "flex",
                                    height: "30px",
                                    // justifyContent: "space-between"
                                }}
                            >
                                {imgrotation && (
                                    <React.Fragment>
                                        <Selectpoint selectpointimage={this.selectpointimage} />
                                        {selectedpoint && (selectedpoint.text + ' ' + selectedpoint.sceneId)}
                                    </React.Fragment>
                                )}
                            </div> <br /><br />

                            <div
                                style={{
                                    display: "flex",
                                    height: "30px",
                                    justifyContent: "space-between"
                                }}
                            >
                                {selectedpoint && (
                                    <Button onClick={() => this.addPoint()}>Add aerial point</Button>
                                )}
                            </div><br /><br />

                            <div
                                style={{
                                    display: "flex",
                                    height: "30px",
                                    justifyContent: "space-between"
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">Scene id</TableCell>
                                            <TableCell align="right">name</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {allPoints.map(row => (
                                            <TableRow key={row.sceneId}>
                                                <TableCell align="right">{row.sceneId}</TableCell>
                                                <TableCell align="right">{row.text}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>


                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
};


const mapStateToProps = state => {
    return {
        aerialImage: state.selectedAreialImageReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //   targetimgdatadispatch: targetimgdata =>
        //     dispatch(targetImgData(targetimgdata)),
        //   clickdonbottomimgdispatch: imgclick =>
        //     dispatch(clickedOnBottomImg(imgclick))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Areial);