import {
  LOGIN_STATUS,
  BACKEND_TOKEN,
  ALL_UPLOADED_IMAGE,
  SELECTED_IMAGE,
  SOURCE_IMAGE_DATA,
  TARGET_IMAGE_DATA,
  SHOULD_IMAGE_UPDATE,
  CLICKED_ON_BOTTOM_IMG,
  HOTSPOT_SINGLE,
  HOTSPOTS_TO_ALL_UPLOADED,
  DELETE_HOTSPOT,
  EDIT_HOTSPOT,
  ADD_HOTSPOT_MAIN_SCENE,
  DELETE_HOTSPOT_MAIN_SCENE,
  EDIT_HOTSPOT_MAIN_SCENE,
  CONNECTED_DATA,
  UUID,
  PROJECT_NAME,
  CREATOR_NAME,
  CATEGORY_DATA,
  DELETE_CATEGORY_DATA_ITEM,
  SELECTED_AERIAL_IMAGE
} from "./actions-types";

export const loginStatus = status => ({
  type: LOGIN_STATUS,
  status
});

export const backendToken = token => ({
  type: BACKEND_TOKEN,
  token
});

export const allUploadImage = imgdata => ({
  type: ALL_UPLOADED_IMAGE,
  imgdata
});

export const selectedImage = selectedimg => ({
  type: SELECTED_IMAGE,
  selectedimg
});

export const sourceImgData = sourceimgdata => ({
  type: SOURCE_IMAGE_DATA,
  sourceimgdata
});
export const targetImgData = targetimgdata => ({
  type: TARGET_IMAGE_DATA,
  targetimgdata
});

export const shouldImgUpdate = imgupdate => ({
  type: SHOULD_IMAGE_UPDATE,
  imgupdate
});

export const clickedOnBottomImg = imgclick => ({
  type: CLICKED_ON_BOTTOM_IMG,
  imgclick
});

export const hotspotsToAllUploaded = (hotspots, hotspotr) => ({
  type: HOTSPOTS_TO_ALL_UPLOADED,
  hotspots,
  hotspotr
});

export const deleteHotspot = (sourceuuid, targetuuid) => ({
  type: DELETE_HOTSPOT,
  sourceuuid,
  targetuuid
});
export const editHotspot = (sourceuuid, targetuuid, editeddata) => ({
  type: EDIT_HOTSPOT,
  sourceuuid,
  targetuuid,
  editeddata
});

export const hotspotSingle = hotspot => ({
  type: HOTSPOT_SINGLE,
  hotspot
});

export const addHotspotMainScene = (uuid, hotspot) => ({
  type: ADD_HOTSPOT_MAIN_SCENE,
  hotspot,
  uuid
});

export const deleteHotspotMainScene = (sourceuuid, targetuuid) => ({
  type: DELETE_HOTSPOT_MAIN_SCENE,
  sourceuuid,
  targetuuid
});

export const editHotspotMainScene = (uuid, editeddata) => ({
  type: EDIT_HOTSPOT_MAIN_SCENE,
  uuid,
  editeddata
});

export const connectedData = connect => ({
  type: CONNECTED_DATA,
  connect
});

export const uuidAction = uuid => ({
  type: UUID,
  uuid
});

export const projectName = title => ({
  type: PROJECT_NAME,
  title
});

export const creatorName = creator => ({
  type: CREATOR_NAME,
  creator
});

//by shafu-dev
export const categoryData = data => ({
  type: CATEGORY_DATA,
  data
});

export const deleteCategoryDataItem = item => ({
  type: DELETE_CATEGORY_DATA_ITEM,
  item
});

// aerial

export const selectedImageAerial = selectedimg => ({
  type: SELECTED_AERIAL_IMAGE,
  selectedimg
});