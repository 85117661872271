export const LOGIN_STATUS = "LOGIN_STATUS";

export const BACKEND_TOKEN = "BACKEND_TOKEN";

export const ALL_UPLOADED_IMAGE = "ALL_UPLOADED_IMAGE";

export const SELECTED_IMAGE = "SELECTED_IMAGE";

export const SOURCE_IMAGE_DATA = "SOURCE_IMAGE_DATA";
export const TARGET_IMAGE_DATA = "TARGET_IMAGE_DATA";

export const SHOULD_IMAGE_UPDATE = "SHOULD_IMAGE_UPDATE";
export const CLICKED_ON_BOTTOM_IMG = "CLICKED_ON_BOTTOM_IMG";

export const HOTSPOTS_TO_ALL_UPLOADED = "HOTSPOTS_TO_ALL_UPLOADED";
export const DELETE_HOTSPOT = "DELETE_HOTSPOT";
export const EDIT_HOTSPOT = "EDIT_HOTSPOT";

export const HOTSPOT_SINGLE = "HOTSPOT_SINGLE";
export const ADD_HOTSPOT_MAIN_SCENE = "ADD_HOTSPOT_MAIN_SCENE";
export const CONNECTED_DATA = "CONNECTED_DATA";
export const EDIT_HOTSPOT_MAIN_SCENE = "EDIT_HOTSPOT_MAIN_SCENE";
export const DELETE_HOTSPOT_MAIN_SCENE = "DELETE_HOTSPOT_MAIN_SCENE";

export const PROJECT_NAME = "PROJECT_NAME";
export const CREATOR_NAME = "CREATOR_NAME";

export const UUID = "UUID";

//by shafu-dev
export const CATEGORY_DATA = "CATEGORY_DATA";
export const DELETE_CATEGORY_DATA_ITEM = "DELETE_CATEGORY_DATA_ITEM";

// areial

export const SELECTED_AERIAL_IMAGE = "SELECTED_AERIAL_IMAGE";  