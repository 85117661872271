import React, { Component } from "react";
import { connect } from "react-redux";
import closeBtn from "../../assests/closeBtn2.svg";
import backBtn from "../../assests/backBtn.svg";

class Modal extends Component {
  state = {
    namingCategory: false,
    selectedScene: null,
    categoryName: ""
  };

  handleBubbling = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleSceneSelect = id => {
    this.setState({
      namingCategory: true,
      selectedScene: id
    });
  };

  handleBackBtn = e => {
    this.setState({
      namingCategory: false
    });
  };

  handleNameChange = e => {
    this.setState({
      categoryName: e.target.value
    });
  };

  render() {
    const { handleModalClose, handleSetCategory } = this.props;
    const { namingCategory, selectedScene, categoryName } = this.state;
    const allimages = this.props.alluploadedimgs;
    // const connecteddata = this.props.connecteddataState

    return (
      <div onClick={handleModalClose} className="modal-backdrop">
        <div className="modal-card" onClick={this.handleBubbling}>
          {namingCategory ? (
            <>
              <div className="modal-header">
                <button className="backBtn" onClick={this.handleBackBtn}>
                  <img src={backBtn} alt="backBtn" />
                </button>
                <h2>Name the category</h2>
                <button className="closeBtn" onClick={handleModalClose}>
                  <img src={closeBtn} alt="closeBtn" />
                </button>
              </div>
              <div className="modal-naming-content">
                <div className="modal-thumbnail">
                  <img
                    src={`https://revlity-thumbnail360.s3.ap-south-1.amazonaws.com/thumbnails/${allimages[selectedScene].uuid}.jpg`}
                    alt="selectedScene"
                  />
                </div>
                <div>
                  <fieldset>
                    <legend>Category Name</legend>
                    <input
                      value={categoryName}
                      onChange={this.handleNameChange}
                      type="text"
                    />
                  </fieldset>
                  <p>Mandatory info :: Please fill to continue</p>
                </div>
                <button
                  onClick={() =>
                    handleSetCategory(categoryName, allimages[selectedScene])
                  }
                >
                  Done
                </button>
              </div>
            </>
          ) : (
              <>
                <div className="modal-header">
                  <h2>Select a scene</h2>
                  <button className="closeBtn" onClick={handleModalClose}>
                    <img src={closeBtn} alt="closeBtn" />
                  </button>
                </div>
                <div className="modal-content">
                  {allimages.map((item, id) => (
                    // return (
                    <div
                      className="modal-thumbnail"
                      onClick={() => this.handleSceneSelect(id)}
                      key={id}
                    >
                      <img
                        src={`https://revlity-thumbnail360.s3.ap-south-1.amazonaws.com/thumbnails/${item.uuid}.jpg`}
                        alt={item}
                      />
                    </div>
                    // );
                  )
                  )}
                </div>
              </>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    alluploadedimgs: state.allUploadedImagesReducer.alluploadedimg,
    connecteddataState: state.connectedDataReducer.connecteddata,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
