import React, { Component } from "react";
import { connect } from "react-redux";
import { loginStatus, backendToken } from "../redux/actions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Card } from "semantic-ui-react";
import GoogleLogin from "react-google-login";
import GoogleButton from "react-google-button";
import "./styles/login.css";
import "./styles/activation.css";
import apiRootUrl from "../api";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progressbar: false,
      notallowed: false
    };
    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseGoogleFail = this.responseGoogleFail.bind(this);
  }
  responseGoogle = response => {
    // console.log(response);
    if (response.accessToken !== undefined) {
      this.setState({ progressbar: true });
      // console.log(response.tokenObj.access_token);
      let token = response.tokenObj.access_token;
      let bodyy = {
        grant_type: "convert_token",
        client_id: "QjbQ7PyHXMzUa7Ab2NsoSgn5uyM2vAJYEYnmbtnA",
        client_secret:
          "kjTk37Bhz8W1pQdANVgxSbu2Stzcvi77to3xbhvYwDTxyNGdr3sgMCxGj1CqTc4wZFRGfNBe89PjI8T2lgQpql4PJKVMOko3wn3JHXIYBhmftTJglKiMRc9wqBUkqEdj",
        backend: "google-oauth2",
        token: token
      };
      fetch(
        // 'https://beta.revlity.com/auth/convert-token'
        `${apiRootUrl}/auth/convert-token/`,
        {
          method: "POST",
          // mode: "cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(bodyy)
        }
      )
        .then(res => res.json())
        .then(res => {
          // console.log("Success:", res);

          let headers = {
            Authorization: `Bearer ${res.access_token}`,
            "Content-Type": "application/json"
          };
          fetch(
            `${apiRootUrl}/api/checkuser/`,
            // 'https://beta.revlity.com/check/',
            {
              method: "GET",
              headers: headers
            }
          ).then(response => {
            console.log(response);
            if (response.ok) {
              localStorage.setItem("accestoken", res.access_token);
              this.props.backendtokenDispatch(res.access_token);
              this.props.loginstatusDispatch(true);
            } else {
              this.setState({ progressbar: false, notallowed: true });
            }
          });

          // localStorage.setItem('accestoken', res.access_token);
          // this.props.backendtokenDispatch(res.access_token)
          // this.props.loginstatusDispatch(true)
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
  responseGoogleFail = response => {
    console.log("LOGIN FAILED");
  };
  componentDidMount = () => {
    // let stylee = document.getElementById('google-logo')
    // stylee.children[0].children[0].style='width: 45px; height: 42.5px; text-align: center; display: block; margin-top: 2px; margin-left: 2px; float: left; background-color: rgb(255, 255, 255); border-radius: 1px; white-space: nowrap;'
  };

  render() {
    const progressbar = this.state.progressbar;
    const notallowed = this.state.notallowed;
    return (
      <div>
        {notallowed ? (
          <div className="activation-page-width-height">
            <div className="activation-content-align">
              <div className="activation-font-head">Activation Required</div>
              <div className="activation-font">
                Contact revlity team to activate this account
              </div>
            </div>
          </div>
        ) : (
            <Grid
              container
              style={{
                minHeight: "100vh",
                backgroundColor: "#1E272E",
                justify: "center",
                alignItems: "center"
              }}
            >
              <Grid style={{ margin: "0 auto" }}>
                <Paper style={{ width: 286, height: 347 }}>
                  <Card style={{ height: "347px", width: "286px" }}>
                    <Card.Content>
                      <Card.Header
                        style={{
                          textAlign: "center",
                          fontSize: "2em",
                          marginTop: "30px"
                        }}
                      >
                        Revlity
                    </Card.Header>
                    </Card.Content>
                    {progressbar ? (
                      <CircularProgress style={{ margin: "auto" }} />
                    ) : null}
                    <Card.Content
                      description="We welcome you as a promising patrons for our VR Creator Studio tool "
                      style={{
                        borderTop: "none",
                        textAlign: "center",
                        fontSize: "16px"
                      }}
                    />
                    <Card.Content
                      extra
                      style={{ alignSelf: "center", marginBottom: "30px" }}
                    >
                      <GoogleLogin
                        render={renderProps => (
                          <GoogleButton
                            onClick={renderProps.onClick}
                            style={{ borderRadius: 3, width: 194 }}
                          // style={{width:'194px',height:'47px',boxShadow:'1px 1px 1px grey',borderRadius:'3px'}}
                          />
                        )}
                        clientId={
                          "895812297872-nh6t2lsb0j56346j7ddua8nfdrihni16.apps.googleusercontent.com"
                        }
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                      />
                    </Card.Content>
                  </Card>
                </Paper>
              </Grid>
            </Grid>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    loginstatusDispatch: status => dispatch(loginStatus(status)),
    backendtokenDispatch: token => dispatch(backendToken(token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
