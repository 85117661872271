import React, { Component } from "react";
import { connect } from "react-redux";
import { categoryData, deleteCategoryDataItem } from "../../redux/actions";
import deleteBtn from "../../assests/deleteBtn.svg";
import "../styles/category.css";
import categoryAddBtn from "../../assests/categoryAddBtn.svg";
import CategoryModal from "./categorymodal.js";

class CategoryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  handleAddBtn = () => {
    this.setState({
      modal: true
    });
  };

  handleModalClose = () => {
    this.setState({
      modal: false
    });
  };

  handleSetCategory = (namee, imageData) => {
    this.handleModalClose();

    let data = {
      name: namee,
      imageuuid: imageData.uuid,
      // imagepath: imageData.imageUrl
    };

    this.props.catogerydataDispatch(data);

    // this.setState(prev => {
    //   return {
    //     categories: [...prev.categories, { name, imageObj }]
    //   };
    // });
  };

  handleDeleteCategoryItem = (id) => {
    this.props.deleteCategoryDataDispatch(id);
  }

  render() {
    const { modal } = this.state;
    const { catagorydata } = this.props;
    return (
      <>
        {modal ? (
          <CategoryModal
            handleModalClose={this.handleModalClose}
            handleSetCategory={this.handleSetCategory}
          />
        ) : null}
        <div className="category-card">
          <div className="category-card-header">
            <h3>Add category</h3>
          </div>
          <div className="category-thumbnail-group">
            <button onClick={this.handleAddBtn} className="category-addBtn">
              <img src={categoryAddBtn} alt="categoryAddBtn" />
            </button>
            {catagorydata
              ? catagorydata.categoryData.map((item, id) => {
                return (
                  <div key={id} className="category-thumbnail">
                    <img
                      src={`https://revlity-thumbnail360.s3.ap-south-1.amazonaws.com/thumbnails/${item.imageuuid}.jpg`}
                      alt={item.name}
                    />
                    <div className="thumbnail-tag">
                      <p>{item.name}</p>
                      <button onClick={() => this.handleDeleteCategoryItem(id)} >
                        <img src={deleteBtn} alt="deleteBtn" />
                      </button>
                    </div>
                  </div>
                );
              })
              : null}
          </div>
          <div className="category-card-footer">
            <p>Set category and go to upload cover info</p>
            {/* <button>Next</button> */}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    catagorydata: state.categoryDataReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    catogerydataDispatch: data => dispatch(categoryData(data)),
    deleteCategoryDataDispatch: item => dispatch(deleteCategoryDataItem(item))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryCard);
