import React, { Component } from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import {
    selectedImageAerial
} from "../../../redux/actions";

import Card from "@material-ui/core/Card";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { Image } from "semantic-ui-react";

class Selectpoint extends Component {
    state = { modalOpen: false }

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })

    selectPointImage = (data) => {
        this.props.selectpointimage(data);
        // this.props.aerialimgdatadispatch(data);
    }

    render() {

        const allimages = this.props.alluploadedimgs
        return (
            <Modal
                trigger={<Button onClick={this.handleOpen}>Select Image</Button>}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                // basic
                size='large'
            >
                <Header icon='browser' content='Select image for aerial view' />
                <Modal.Content>

                    <Card style={{ height: 320 }}>

                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-around",
                                margin: "14px 0 14px 32px"
                            }}
                        >
                            <GridList
                                cellHeight={300}
                                style={{
                                    height: 300,
                                    margin: 0,
                                    width: "100%",
                                    paddingBottom: "50px"
                                }}
                                spacing={16}
                            >
                                {allimages.alluploadedimg.map((x, i) => (
                                    <GridListTile
                                        key={i}
                                        style={{
                                            padding: 0,
                                            width: 200,
                                            height: 100,
                                            marginRight: "2%",
                                            marginTop: 6
                                        }}
                                    >
                                        <img
                                            width="200px"
                                            height="100px"
                                            style={{ cursor: "pointer" }}
                                            src={`https://revlity-thumbnail360.s3.ap-south-1.amazonaws.com/thumbnails/${
                                                x.bucketpath
                                                }.jpg`}
                                            onClick={() => this.selectPointImage(x)}
                                            alt={x.name}
                                        />

                                    </GridListTile>
                                ))}
                            </GridList>
                        </div>
                    </Card>

                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={this.handleClose} inverted>
                        <Icon name='checkmark' /> Done
          </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}


const mapStateToProps = state => {
    return {
        alluploadedimgs: state.allUploadedImagesReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //   aerialimgdatadispatch: selectedimg =>
        //     dispatch(selectedImageAerial(selectedimg))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Selectpoint);