import React, { Component } from "react";
import { connect } from "react-redux";
import { allUploadImage } from "../redux/actions";
import { NavLink } from "react-router-dom";
import fileSize from "filesize";
import nextIcon from "../assests/Next-icon.svg";
import FileInput from "react-fine-uploader/file-input";
import CancelButton from "react-fine-uploader/cancel-button";
import DeleteButton from "react-fine-uploader/delete-button";
import FineUploaderS3 from "fine-uploader-wrappers/s3";

import closeBtn from "../assests/closeBtn.svg";
import uploadBtn from "../assests/upload-icon.svg";
import "./styles/uploadpage.css";

const thambnail360function = "https://l1a17qw67k.execute-api.ap-south-1.amazonaws.com/thumbnail360"

const uploader = new FineUploaderS3({
  options: {
    credentials: {
      accessKey: "AKIAS5MHLIX7TFNQLTS6",
      expiration: "2022-12-23T10:26:00.996Z",
      secretKey: "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"
    },
    request: {
      endpoint: "https://revlity-old-source.s3.ap-south-1.amazonaws.com",
      accessKey: "AKIAS5MHLIX7TFNQLTS6",
    },
    signature: {
      endpoint: "https://s3.ap-south-1.amazonaws.com/revlity-old-source/",
      version: 3,
    },
    deleteFile: {
      enabled: true,
      endpoint: "https://revlity-old-source.s3.ap-south-1.amazonaws.com"
    },

    // validation: {
    //   allowedExtensions: ["gif", "jpeg", "jpg", "png", "tif"],
    //   acceptFiles: "image/gif, image/jpeg, image/png, image/tiff",
    // },

    retry: {
      enableAuto: false
    },
    chunking: {
      enabled: true,
      partSize: 200000000
    },
    objectProperties: {
      region: "ap-south-1"
    },
    // scaling: {
    //   sendOriginal: false,

    //   sizes: [{ name: "", maxSize: 4096 }]
    // },
    callbacks: {
      onComplete: (id, name, response) => {
        console.log(id, name, response)
        // let a1 = uploader.methods.getFile(id);
        // if (window.Blob && a1 instanceof Blob) {
        //   var URL =
        //     window.URL && window.URL.createObjectURL
        //       ? window.URL
        //       : window.webkitURL && window.webkitURL.createObjectURL
        //         ? window.webkitURL
        //         : null;
        //   if (!URL) {
        //     throw Error("No createObjectURL function found to create blob url");
        //   }
        // let bucket = uploader.methods.getBucket(id);
        // let path = uploader.methods.getKey(id);
        // let imgid = uploader.methods.getUuid(id);
        // let imgname = uploader.methods.getName(id);
        // let imgnamer = imgname.replace(/.jpg|.png|.jpeg/i, "");
        // let imgblob = URL.createObjectURL(a1);
        // let imgdata = {
        //   uuid: imgid,
        //   name: imgnamer,
        //   blob: imgblob,
        //   imageorginalname: imgname,
        //   // bucketname:bucket,http://revlity.s3.ap-south-1.amazonaws.com
        //   bucketpath: path,
        //   hotspots: []
        // };
        // this.props.uploadimagedata(imgdata);
        // }
      }
    }
  }
});

const isFileGone = status => {
  return ["canceled", "deleted"].indexOf(status) >= 0;
};

class Uploadpage extends Component {
  state = {
    submittedFiles: [],
    wholeSize: 0,
    progressArray: [],
    uploadsDone: false
  };

  componentDidMount() {
    uploader.on("allComplete", (a, b) => {

      let alluuids = [];
      a.map(x => {
        let imgid = uploader.methods.getUuid(x);
        alluuids.push(imgid);
      })


      var promises = alluuids.map(url => fetch(`${thambnail360function}?imguuid=${url}`).then(y => y.text()));
      Promise.all(promises).then(results => {
        this.setState({
          uploadsDone: true
        });
      });

    });

    uploader.on("submitted", id => {
      // Cleans file extension from filename
      let str = uploader.methods.getName(id);
      str = str.slice(0, str.lastIndexOf("."));
      uploader.methods.setName(id, str);

      // Adds submitted file ids to state
      let { submittedFiles } = this.state;
      this.setState({ submittedFiles: [...submittedFiles, id] });

      // Calculates the whole size of files
      this.calculateWholeSize();
    });

    uploader.on("statusChange", (id, oldStatus, newStatus) => {
      if (isFileGone(newStatus)) {
        const submittedFiles = this.state.submittedFiles;
        const indexToRemove = submittedFiles.indexOf(id);

        // Removes cancelled file
        submittedFiles.splice(indexToRemove, 1);
        this.setState({ submittedFiles });
        // Calculates the whole size of files
        this.calculateWholeSize();
      }

      if (this.state.submittedFiles.length === 0) {
        this.setState({
          uploadsDone: false
        });
      }

      this.calculateWholeSize();
    });

    uploader.on("progress", (id, name, uploaded, total) => {

      let progressArrayCopy = JSON.parse(
        JSON.stringify(this.state.progressArray)
      );

      progressArrayCopy[id] = { uploaded, total };

      this.setState({
        progressArray: progressArrayCopy
      });
    });
  }

  calculateWholeSize = () => {
    let wholeSize = this.state.submittedFiles
      .map(id => uploader.methods.getSize(id))
      .reduce((acc, cur) => {
        return acc + cur;
      }, 0);
    this.setState({ wholeSize });
  };

  handleFilenameChange = (e, id) => {
    uploader.methods.setName(id, e.target.value);
  };

  handleGoToStudio = () => {
    this.state.submittedFiles.map(id => {
      let path = uploader.methods.getKey(id);
      let imgid = uploader.methods.getUuid(id);
      let imgnamer = uploader.methods.getName(id);
      // let imgnamer = imgname.replace(/.jpg|.png|.jpeg/i, "");
      // let imgblob = URL.createObjectURL(a1);
      let xx = {
        uuid: imgid,
        name: imgnamer,
        blob: "",
        imageorginalname: imgnamer,
        bucketpath: path,
        hotspots: []
      };
      this.props.uploadimagedata(xx);

      let functionurlthumbnail = `https://l1a17qw67k.execute-api.ap-south-1.amazonaws.com/thumbnail360?imguuid=${imgid}`
      fetch(functionurlthumbnail)

    });
    // let filess = this.state.submittedFiles

    // this.props.uploadimagedata(newArray)
  };

  render() {
    return (
      <div>
        {/* <div className="nav" /> */}
        <div className="gallery">
          <div className="gallery-header">
            <h1>Upload 360 panorama</h1>
            {this.state.uploadsDone ? (
              <NavLink to="/studio">
                <div className="nextIcon">
                  <p onClick={this.handleGoToStudio}>Go to Studio</p>
                  <img src={nextIcon} width="18px" height="18px" alt="next icon" />
                </div>
              </NavLink>
            ) : null}
          </div>
          <div className="inner-box">
            <div className="gallery-sidebar">
              <FileInput multiple accept="image/*" uploader={uploader}>
                <div className="uploadGroup">
                  <span>Open</span>{" "}
                  <img className="uploadBtn" src={uploadBtn} alt="uploadBtn" />
                </div>
              </FileInput>
              <p>
                {this.state.submittedFiles.length} pano /{" "}
                {fileSize(this.state.wholeSize)}
              </p>
            </div>
            <div className="gallery-thumbnails">
              {this.state.submittedFiles.map(id => (
                <div className="thumbnail" key={id}>
                  <CancelButton id={id} uploader={uploader}>
                    <img src={closeBtn} alt="Cancel" />
                  </CancelButton>
                  <DeleteButton id={id} uploader={uploader}>
                    <img src={closeBtn} alt="Delete" />
                  </DeleteButton>
                  <div className="thumbnail-img">
                    <img
                      src={URL.createObjectURL(uploader.methods.getFile(id))}
                      alt="thumbnail"
                    />
                  </div>
                  <ProgressBar
                    id={id}
                    progressArray={this.state.progressArray}
                  />
                  <input
                    defaultValue={uploader.methods.getName(id)}
                    onChange={e => this.handleFilenameChange(e, id)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ProgressBar = ({ id, progressArray }) => {
  const { uploaded, total } = progressArray[id] || {};
  const width = ((uploaded / total) * 100).toString() + "%";
  return width === "100%" ? (
    <div style={{ height: "4px", marginBottom: "1px" }} />
  ) : (
      <div
        style={{
          height: "4px",
          width: width || "0%",
          backgroundColor: "#1E272E",
          transition: ".3s ease-in",
          marginBottom: "1px"
        }}
      />
    );
};

const mapStateToProps = state => {
  return {
    // alluploadedimgs1 : state.allUploadedImagesReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadimagedata: alluploadedimg => dispatch(allUploadImage(alluploadedimg))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Uploadpage);
