import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'semantic-ui-css/semantic.min.css';
import './components/styles/main.css'
import Routess from './routes'

const THEME = createMuiTheme({
  typography: {
   "fontFamily": "\"IBM Plex Sans\",  sans-serif"
  }
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={THEME}>
      <Provider store={store}>
        <div>
          <Routess/>
        </div>
      </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
