import React, { Component } from "react";
import { connect } from "react-redux";
import {
  selectedImage,
  shouldImgUpdate,
  clickedOnBottomImg,
  targetImgData,
  connectedData,
  addHotspotMainScene,
  hotspotsToAllUploaded,
  deleteHotspot,
  editHotspot,
  editHotspotMainScene,
  deleteHotspotMainScene
} from "../../redux/actions";
import Grid from "@material-ui/core/Grid";
// import Paper from '@material-ui/core/Paper';
// import * as THREE from 'three';
import Button from "@material-ui/core/Button";

import { Image } from "semantic-ui-react";
import apiRootUrl from "../../api";
import "../styles/studio.css";
import defaultimg from "../../assests/EMPTY-STATE.jpg";
// import defaultimg from '../../assests/select.svg';
// import hotspotimage from '../../assests/hotspotimg.png';
import alignIcn from "../../assests/alignIcn.svg";
import buttonrealign from "../../assests/Button-realign.svg";
import buttonconnect from "../../assests/btnConnect.svg";
import lineonthreejs from "../../assests/linesonthreejs.png";
import deleteimg from "../../assests/deleteBtn.svg";
import canclebtn from "../../assests/cancel-btn.svg";

import CubeAdd from "./cube";

class StudioMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendediteapi: false,

      alignbutton: false,
      connectbutton: false,
      deletebtn: false,
      canSelect: true,
      gridlines: false,
      realignbtn: false,
      edit: false
    };
    this.sendediteapi = this.sendediteapi.bind(this);
    this.selectclick = this.selectclick.bind(this);
    this.alignbutton = this.alignbutton.bind(this);
    this.canclebutton = this.canclebutton.bind(this);
    this.realignbutton = this.realignbutton.bind(this);
    this.deletebutton = this.deletebutton.bind(this);
    this.connectbuttone = this.connectbuttone.bind(this);

    this.Cubee = "";
  }

  componentDidMount() {
    this.Cubee = new CubeAdd();
    // var clickevent =  this.Cubee.container1Return();
    document
      .getElementById("container1")
      .addEventListener("click", this.clickOnContainer.bind(this), false);
  }

  clickOnContainer(event) {
    let targetuuid = this.Cubee.clickOnHotspot(event.clientX, event.clientY);
    console.log(this.Cubee.getRotation().rotationSouce, "*************");

    if (targetuuid) {
      let alldata = this.props.alluploadedimgs.alluploadedimg;
      let gettarget = alldata.find(x => x.uuid === targetuuid);
      let imagee = `https://revlity-old-source.s3.ap-south-1.amazonaws.com/${gettarget.bucketpath}`;
      this.props.targetimgdatadispatch(gettarget);
      this.Cubee.changeSphere2texture(imagee);
      this.setState({ deletebtn: true, realignbtn: true });
    }
  }

  sendediteapi() {
    let headers = {
      Authorization: `Bearer ${this.props.backendtoken}`,
      "Content-Type": "application/json"
    };
    let draftdataa = this.props.alluploadedimgs.alluploadedimg;
    let connectdataa = this.props.connecteddata.connecteddata;
    let dr = JSON.stringify(draftdataa);
    let cd = JSON.stringify(connectdataa);
    let uuidd = this.props.uuid;
    let apiurl = `${apiRootUrl}/api/editdraft/${uuidd}/`;
    fetch(apiurl, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({
        draftdata: dr,
        configdata: cd
      })
    })
      .then(res => res.json())
      .then(res => {
        // console.log(res, "response from api");
        // this.setState({sendediteapi:false});
      });
    this.setState({ sendediteapi: false });
    this.props.clickdonbottomimgdispatch(true);
  }

  selectclick(container) {
    this.props.selectimgdispatch(container);
    this.props.imageupdatedispatch(true);
    var border1 = document.getElementById("container1");
    var border2 = document.getElementById("container2");
    if (container === "source") {
      border1.style.border = "4px solid #FFCE01";
      border2.style.border = "4px solid #B9C0C7";
    } else if (container === "target") {
      border1.style.border = "4px solid #B9C0C7";
      border2.style.border = "4px solid #FFCE01";
    }
  }

  alignbutton() {
    let border1 = document.getElementById("container1");
    let border2 = document.getElementById("container2");
    border1.style.border = "4px solid #B9C0C7";
    border2.style.border = "4px solid #B9C0C7";
    let imagename = document.getElementsByClassName("imagename");
    for (let index = 0; index < imagename.length; index++) {
      const element = imagename[index];
      element.style.backgroundColor = "#1E272E";
      element.style.color = "#FFFFFF";
    }
    this.props.imageupdatedispatch(false);
    if (this.state.realignbtn) {
      this.setState({ edit: true });
    }
    this.setState({
      connectbutton: true,
      alignbutton: false,
      realignbtn: false,
      canSelect: false,
      gridlines: true
    });

    // let r1 = document.getElementById("grid1");
    // r1.removeEventListener('click', () => this.selectclick("source"))
    // let r2 = document.getElementById("grid2");
    // r2.removeEventListener('click', () => this.selectclick("target"))
  }

  realignbutton() { }
  canclebutton() {
    let tdata = {
      blob: defaultimg,
      name: "Please Select image"
    };
    this.props.targetimgdatadispatch(tdata);

    this.Cubee.changeSphere2texture(); // default images passes

    this.setState({
      alignbutton: false,
      connectbutton: false,
      deletebtn: false,
      canSelect: true,
      gridlines: false,
      realignbtn: false
    });

    this.selectclick("target");
    this.selectclick("source");
    let imagename = document.getElementsByClassName("imagename");
    for (let index = 0; index < imagename.length; index++) {
      const element = imagename[index];
      element.style.backgroundColor = "#FFCE01";
      element.style.color = "#1b1c1d";
    }
    this.props.clickdonbottomimgdispatch(true);
  }

  connectbuttone() {
    this.props.imageupdatedispatch(true);

    console.log(this.props.connecteddata);

    let hotspotSourceData = this.Cubee.getHotspotSource();
    let hotspotReturnData = this.Cubee.getHotspotReturn();
    let rotations = this.Cubee.getRotation();
    console.log(rotations);

    // let sourceRotation = rotations.rotationSouce;
    // let targetRotation = rotations.rotationTarget;

    this.setState({
      alignbutton: false,
      connectbutton: false,
      gridlines: false,
      canSelect: true
    });

    let source = this.props.sourcedata;
    let target = this.props.targetdata;

    let hotspotSource = {
      sourceuuid: source.uuid,
      targetuuid: target.uuid,
      target: target.uuid,
      rotHotspot: rotations.rotationSouce,
      sourceRotation: rotations.rotationSouce,
      targetRotation: rotations.rotationTarget,
      position: hotspotSourceData.position,
      rotation: hotspotSourceData.rotation,

      arrowpos: hotspotSourceData.arrowpos,
      arrowrot: hotspotSourceData.arrowrot
    };

    let rotHotspotReturn = Math.sign(rotations.rotationTarget);
    if (rotHotspotReturn === 1) {
      rotHotspotReturn = (rotations.rotationTarget - Math.PI) % Math.PI;
    } else if (rotHotspotReturn === -1) {
      rotHotspotReturn = (rotations.rotationTarget + Math.PI) % Math.PI;
    } else if (rotHotspotReturn === 0) {
      rotHotspotReturn = 3.12414 % Math.PI;
    }

    let hotspotReturn = {
      sourceuuid: source.uuid,
      targetuuid: target.uuid,
      target: source.uuid,
      rotHotspot: rotHotspotReturn,
      sourceRotation: (rotations.rotationTarget + Math.PI) % (2 * Math.PI),
      targetRotation: (rotations.rotationSouce + Math.PI) % (2 * Math.PI),
      position: hotspotReturnData.position,
      rotation: hotspotReturnData.rotation,
      isReturnHotspot: true,

      arrowpos: hotspotReturnData.arrowpos,
      arrowrot: hotspotReturnData.arrowrot
    };

    if (this.state.edit) {
      this.props.edithotspotdispatch(source.uuid, target.uuid, hotspotSource);
      this.props.edithotspotdispatch(target.uuid, source.uuid, hotspotReturn);
      this.props.edithotspotmaindispatch(source.uuid, hotspotSource);
      this.props.edithotspotmaindispatch(target.uuid, hotspotReturn);
    } else {
      this.props.addhotspottoalluploaddispatch(hotspotSource, hotspotReturn);

      let sourceef = {
        uuid: source.uuid,
        name: source.name,
        imageUrl: `https://revlity-old-source.s3.ap-south-1.amazonaws.com/${source.bucketpath}`,
        hotspots: [hotspotSource]
      };

      let targettf = {
        uuid: target.uuid,
        name: target.name,
        imageUrl: `https://revlity-old-source.s3.ap-south-1.amazonaws.com/${target.bucketpath}`,
        hotspots: [hotspotReturn]
      };

      if (this.props.connecteddata !== null) {
        if (
          this.props.connecteddata.connecteddata.find(
            uid => uid.uuid === sourceef.uuid
          ) === undefined
        ) {
          this.props.connectdatadispatch(sourceef);
        } else {
          this.props.addhotspottomainscenedispatch(
            sourceef.uuid,
            hotspotSource
          );
        }

        if (
          this.props.connecteddata.connecteddata.find(
            uid => uid.uuid === targettf.uuid
          ) === undefined
        ) {
          this.props.connectdatadispatch(targettf);
        } else {
          this.props.addhotspottomainscenedispatch(
            targettf.uuid,
            hotspotReturn
          );
        }
      } else if (this.props.connecteddata === null) {
        this.props.connectdatadispatch(sourceef);
        this.props.connectdatadispatch(targettf);
      }
    }

    let tdata = {
      blob: defaultimg,
      name: "Please Select image"
    };
    this.props.targetimgdatadispatch(tdata);

    this.Cubee.changeSphere2texture(); // This will reset target to default

    this.setState({ edit: false, deletebtn: false });

    this.selectclick("target");
    this.selectclick("source");
    let imagename = document.getElementsByClassName("imagename");
    for (let index = 0; index < imagename.length; index++) {
      const element = imagename[index];
      element.style.backgroundColor = "#FFCE01";
      element.style.color = "#1b1c1d";
    }
    this.props.clickdonbottomimgdispatch(true);
    this.setState({ sendediteapi: true });
  }

  deletebutton() {
    let sourceuuid = this.props.sourcedata.uuid;
    let targetuuid = this.props.targetdata.uuid;
    this.props.deletehotspotdispatch(sourceuuid, targetuuid);
    this.props.deletehotspotdispatch(targetuuid, sourceuuid);
    this.props.deletehotspotmaindispatch(sourceuuid, targetuuid);
    this.props.deletehotspotmaindispatch(targetuuid, sourceuuid);
    let tdata = {
      blob: defaultimg,
      name: "Please Select image"
    };
    this.props.targetimgdatadispatch(tdata);

    this.Cubee.changeSphere2texture();

    this.setState({ deletebtn: false, realignbtn: false });

    this.selectclick("target");
    this.selectclick("source");
    this.props.clickdonbottomimgdispatch(true);
    this.canclebutton();
    this.setState({ sendediteapi: true });
  }

  componentDidUpdate() {
    if (this.state.sendediteapi) {
      this.sendediteapi();
      // this.setState({sendediteapi:false});
    }
    if (this.props.imageupdate && this.props.clickbottomimg) {
      // this.state.scene1.children[0].children[0].scale.set(0.001,0.001,0.001)
      if (this.props.selectedimage === "source") {
        let imgg11 = this.props.sourcedata.bucketpath;
        let imgg1 = `https://revlity-old-source.s3.ap-south-1.amazonaws.com/${imgg11}`;

        this.Cubee.changeSphere1texture(imgg1);
        this.Cubee.resetControls();
      } else if (this.props.selectedimage === "target") {
        let imgg22 = this.props.targetdata.bucketpath;
        let imgg2 = `https://revlity-old-source.s3.ap-south-1.amazonaws.com/${imgg22}`;

        this.Cubee.changeSphere2texture(imgg2);
        this.Cubee.resetControls();
      }
      if (
        this.props.sourcedata.name !== "Please Select image" &&
        this.props.targetdata.name !== "Please Select image"
      ) {
        this.setState({ alignbutton: true });
      }

      // let sceneee = this.state.scene1
      let alldata = this.props.alluploadedimgs.alluploadedimg;
      let scenedata = alldata.find(u => u.uuid === this.props.sourcedata.uuid);

      // clear old hotspots

      this.Cubee.clearOldHotspots();

      if (scenedata) {
        if (scenedata.hotspots !== []) {
          for (let i = 0; i < scenedata.hotspots.length; i++) {
            let position = scenedata.hotspots[i].position;
            let rotation = scenedata.hotspots[i].rotation;
            let userdata = scenedata.hotspots[i];
            this.Cubee.generateHotspots(position, rotation, userdata);
          }
        }
      }
    }
    this.props.clickdonbottomimgdispatch(false);
  }

  render() {
    let alignbutton = this.state.alignbutton;
    let connectbuttonn = this.state.connectbutton;
    let gridliness = this.state.gridlines;
    let deletebtnn = this.state.deletebtn;
    let realignbuttonn = this.state.realignbtn;
    // console.log(this.props.alluploadedimgs);
    return (
      <div style={{ marginLeft: "5%", marginRight: "5%", height: "100%" }}>
        <Grid item xs={12} style={{ height: "100%" }}>
          <Grid
            style={{ height: "100%" }}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid
              id="grid1"
              item
              style={{ height: "100%", width: "40%", position: "relative" }}
            >
              <div
                id="container1"
                style={{
                  width: "100%",
                  height: "calc(100% - 36px)",
                  border: "4px solid #B9C0C7"
                }}
                onClick={() =>
                  this.state.canSelect ? this.selectclick("source") : null
                }
              />
              {gridliness ? (
                <div
                  style={{
                    width: "100%",
                    height: "calc(100% - 44px)",
                    position: "absolute",
                    zIndex: 100,
                    top: 4,
                    pointerEvents: "none"
                  }}
                >
                  <img
                    src={lineonthreejs}
                    width="100%"
                    height="100%"
                    alt="lines on three"
                  />
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  height: "30px",
                  justifyContent: "space-between"
                }}
              >
                <div
                  className="imagename"
                  style={{
                    backgroundColor: "#FFCE01",
                    paddingTop: 3,
                    borderRadius: 5
                  }}
                >
                  {this.props.sourcedata.name}
                </div>
                {deletebtnn ? (
                  <Button
                    variant="contained"
                    style={{
                      padding: "10px 15px",
                      backgroundColor: "#FFCE01",
                      boxShadow: "none",
                      textTransform: "capitalize",
                      display: "flex",
                      alignItems: "center"
                    }}
                    onClick={this.deletebutton}
                  >
                    Remove
                    <Image
                      style={{ marginLeft: "10px" }}
                      src={deleteimg}
                      width="20px"
                      height="20px"
                    />
                  </Button>
                ) : null}
              </div>
            </Grid>

            {alignbutton ? (
              <Grid item style={{ width: "10%" }}>
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    height: 40,
                    padding: 0,
                    backgroundColor: "#FFCE01",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center"
                  }}
                  onClick={this.alignbutton}
                >
                  Align scene
                  <Image
                    style={{ marginLeft: "10px" }}
                    src={alignIcn}
                    width="20px"
                    height="20px"
                  />
                </Button>
                <Button
                  variant="contained"
                  style={{
                    width: "60%",
                    height: 21,
                    padding: 0,
                    marginLeft: "20%",
                    marginTop: 20,
                    backgroundColor: "#FFCE01",
                    boxShadow: "none",
                    textTransform: "capitalize"
                  }}
                  onClick={this.canclebutton}
                >
                  {/* <Image src={canclebtn} width="100%" height="100%" /> */}
                  Cancel
                </Button>
              </Grid>
            ) : connectbuttonn ? (
              <Grid item style={{ width: "10%" }}>
                <Button
                  variant="text"
                  disableRipple
                  style={{
                    width: "100%",
                    height: 40,
                    padding: 0,
                    backgroundColor: "#FFCE01",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center"
                  }}
                  onClick={this.connectbuttone}
                >
                  Connect scene
                  <Image
                    style={{ marginLeft: "10px" }}
                    src={alignIcn}
                    width="20px"
                    height="20px"
                    src={buttonconnect}
                  />
                </Button>
                <Button
                  variant="text"
                  disableRipple
                  style={{
                    width: "60%",
                    height: 21,
                    padding: 0,
                    marginLeft: "20%",
                    marginTop: 20,
                    backgroundColor: "#FFCE01",
                    boxShadow: "none",
                    textTransform: "capitalize"
                  }}
                  onClick={this.canclebutton}
                >
                  Cancel
                  {/* <Image src={canclebtn} width="100%" height="100%" /> */}
                </Button>
              </Grid>
            ) : realignbuttonn ? (
              <Grid item style={{ width: "10%" }}>
                <Button
                  variant="text"
                  disableRipple
                  style={{
                    width: "100%",
                    height: 40,
                    padding: 0,
                    backgroundColor: "#FFCE01",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center"
                  }}
                  onClick={this.alignbutton}
                >
                  Re-align scene
                  {/* <Image src={buttonrealign} width="100%" height="100%" /> */}
                </Button>
                <Button
                  variant="text"
                  disableRipple
                  style={{
                    width: "60%",
                    height: 21,
                    padding: 0,
                    marginLeft: "20%",
                    marginTop: 20,
                    backgroundColor: "#FFCE01",
                    boxShadow: "none",
                    textTransform: "capitalize"
                  }}
                  onClick={this.canclebutton}
                >
                  Cancel
                  {/* <Image src={canclebtn} width="100%" height="100%" /> */}
                </Button>
              </Grid>
            ) : null}

            <Grid
              id="grid2"
              item
              style={{ height: "100%", width: "40%", position: "relative" }}
              onClick={() =>
                this.state.canSelect ? this.selectclick("target") : null
              }
            >
              <div
                id="container2"
                style={{
                  width: "100%",
                  height: "calc(100% - 36px)",
                  border: "4px solid #B9C0C7"
                }}
              />
              {gridliness ? (
                <div
                  style={{
                    width: "100%",
                    height: "calc(100% - 44px)",
                    position: "absolute",
                    zIndex: 100,
                    top: 4,
                    pointerEvents: "none"
                  }}
                >
                  <img
                    src={lineonthreejs}
                    width="100%"
                    height="100%"
                    alt="lines on three"
                  />
                </div>
              ) : null}
              <div style={{ height: "30px", display: "flex" }}>
                <div
                  className="imagename"
                  style={{
                    backgroundColor: "#FFCE01",
                    paddingTop: 3,
                    borderRadius: 5
                  }}
                >
                  {this.props.targetdata.name}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    backendtoken: state.backendtokenReducer,
    selectedimage: state.selectedImageReducer,
    sourcedata: state.sourceImageDataReducer,
    targetdata: state.targetImageDataReducer,
    imageupdate: state.shouldimgUpdateReducer,
    clickbottomimg: state.clickedOnBottomImgReducer,
    alluploadedimgs: state.allUploadedImagesReducer,
    connecteddata: state.connectedDataReducer,
    uuid: state.uuidReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectimgdispatch: selectedimg => dispatch(selectedImage(selectedimg)),
    imageupdatedispatch: imgupdate => dispatch(shouldImgUpdate(imgupdate)),
    clickdonbottomimgdispatch: imgclick =>
      dispatch(clickedOnBottomImg(imgclick)),
    targetimgdatadispatch: targetimgdata =>
      dispatch(targetImgData(targetimgdata)),
    connectdatadispatch: connectdata => dispatch(connectedData(connectdata)),
    addhotspottomainscenedispatch: (uuid, hotspot) =>
      dispatch(addHotspotMainScene(uuid, hotspot)),
    addhotspottoalluploaddispatch: (hotspots, hotspotr) =>
      dispatch(hotspotsToAllUploaded(hotspots, hotspotr)),
    deletehotspotdispatch: (sourceuuid, targetuuid) =>
      dispatch(deleteHotspot(sourceuuid, targetuuid)),
    edithotspotdispatch: (sourceuuid, targetuuid, editeddata) =>
      dispatch(editHotspot(sourceuuid, targetuuid, editeddata)),
    edithotspotmaindispatch: (uuid, editeddata) =>
      dispatch(editHotspotMainScene(uuid, editeddata)),
    deletehotspotmaindispatch: (sourceuuid, targetuuid) =>
      dispatch(deleteHotspotMainScene(sourceuuid, targetuuid))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudioMain);
