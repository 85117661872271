// const apiRootUrl = 'http://127.0.0.1:8000';
// const apiRootUrl = 'https://www.revlity.com';
// const apiRootUrl = 'https://dev.revlity.com';

var apiRootUrl;

if (window.location.host === "create.revlity.com") {
  apiRootUrl = 'https://newapirev.revlity.com';
} else {
  apiRootUrl = 'https://newapirev.revlity.com';
  // apiRootUrl = 'http://127.0.0.1:8000';
}
export default apiRootUrl;